import { paths } from "@app/constants/paths";
import { useGetCycleStatement } from "@app/helpers";
import type { RootState } from "@app/redux";
import { downloadFile } from "@app/utils/download-file";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import type { SummaryOfTradesState } from "./models/summary-of-trades-state";
import type { Properties, ViewProperties } from "./properties";
import { SummaryOfTradesCardView } from "./summary-of-trades-card-view";

export const SummaryOfTradesCard = (props: Properties) => {
	const navigate = useNavigate();
	const [getCycleStatement] = useGetCycleStatement();

	const { cyclesError } = useSelector(
		(rootState: RootState) => rootState.clients,
	);

	const defaultState: SummaryOfTradesState = {
		showErrors: false,
		viewStatement: false,
	};

	const [state, setState] = React.useState<SummaryOfTradesState>(defaultState);

	const getTradeStatement = (
		id: number,
		callback: (contentType: string, data: string, fileName?: string) => void,
	) => {
		getCycleStatement(id, (response) => {
			if (
				response &&
				!Array.isArray(response) &&
				response.contentType &&
				response.data
			) {
				callback(response.contentType, response.data, response.fileName);
			} else {
				setState({ ...state, showErrors: true });
			}
		});
	};

	const onClearErrors = () => {
		setState({ ...state, showErrors: false });
	};

	const onCloseTradeStatement = () => {
		setState({
			...state,
			statementContentType: undefined,
			statementData: undefined,
			statementFileName: undefined,
			viewStatement: false,
		});
	};

	const onDownloadTradeStatement = (id: number) => {
		getTradeStatement(id, (contentType, data, fileName) =>
			downloadFile(contentType, data, fileName || new Date().toLocaleString()),
		);
	};

	const onViewTradeStatement = (id: number) => {
		getTradeStatement(id, (contentType, data, fileName) => {
			setState({
				...state,
				statementContentType: contentType,
				statementData: data,
				statementFileName: fileName,
				statementId: id,
				viewStatement: true,
			});
		});
	};

	const onViewAll = () => {
		navigate(paths.trades);
	};

	const viewProps: ViewProperties = {
		...props,
		errors: cyclesError?.join(" "),
		showErrors: state.showErrors,
		statementContentType: state.statementContentType,
		statementData: state.statementData,
		statementFileName: state.statementFileName,
		statementId: state.statementId,
		viewStatement: state.viewStatement,
		onClearErrors: onClearErrors,
		onCloseTradeStatement: onCloseTradeStatement,
		onDownloadTradeStatement: onDownloadTradeStatement,
		onViewAll: onViewAll,
		onViewTradeStatement: onViewTradeStatement,
	};

	return (
		<BreakpointWrapper
			desktopView={<SummaryOfTradesCardView {...viewProps} />}
			mobileView={<SummaryOfTradesCardView {...viewProps} mobile />}
		/>
	);
};
