import React, { useState } from "react";
import type { PaginatorState } from "./models/paginator-state";
import { PaginatorView } from "./paginator-view";
import type { Properties } from "./properties";

const Paginator = (props: Properties) => {
	const defaultState: PaginatorState = {
		blockSize: 3,
		currentPage: props.currentPage || 1,
		totalPages: props.totalPages || 0,
	};

	const [state, setState] = useState<PaginatorState>(defaultState);

	const getBlockDelta = () => {
		return state.currentPage % state.blockSize;
	};

	const onNavigatePage = (page: number) => {
		const viablePage = Math.max(1, Math.min(state.totalPages, page));

		setState({ ...state, currentPage: viablePage });

		props.onNavigatePage(viablePage);
	};

	const onNavigateNextBlock = () => {
		const delta = getBlockDelta();
		const candidatePage = Math.min(
			state.currentPage +
				state.blockSize -
				(delta > 0 ? delta : state.blockSize) +
				1,
			state.totalPages,
		);

		onNavigatePage(candidatePage);
	};

	const onNavigatePreviousBlock = () => {
		const delta = getBlockDelta();
		const candidatePage = Math.max(
			state.currentPage - (delta > 0 ? delta : state.blockSize),
			1,
		);

		onNavigatePage(candidatePage);
	};

	const onNavigateNextPage = () => {
		onNavigatePage(state.currentPage + 1);
	};

	const onNavigatePreviousPage = () => {
		onNavigatePage(state.currentPage - 1);
	};

	React.useEffect(() => {
		setState({ ...state, totalPages: props.totalPages });
	}, [props.totalPages]);

	React.useEffect(() => {
		setState({
			...state,
			blockSize: Math.max(1, props.blockSize || defaultState.blockSize),
		});
	}, [props.blockSize]);

	return (
		<PaginatorView
			{...props}
			currentPage={state.currentPage}
			safeBlockSize={state.blockSize}
			onNavigatePage={onNavigatePage}
			onNavigateNextPage={onNavigateNextPage}
			onNavigatePreviousPage={onNavigatePreviousPage}
			onNavigateNextBlock={onNavigateNextBlock}
			onNavigatePreviousBlock={onNavigatePreviousBlock}
		/>
	);
};

export { Paginator };
