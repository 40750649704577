import { balanceAction } from "@app/constants/balance-options";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import { BalanceCardView } from "./balance-card-view";

export const BalanceCard = (props: {
	className?: string;
	balance: number;
	balanceLastUpdated: string;
	balanceLastUpdatedWarning: string;
	onDeposit?: () => void;
	onWithdrawOption: (value: string) => void;
}) => {
	const onBalanceAction = (action: string) => {
		switch (action) {
			case balanceAction.addFunds: {
				props.onDeposit?.();
				break;
			}
			case balanceAction.withdraw: {
				props.onWithdrawOption(action);
				break;
			}
			default: {
				break;
			}
		}
	};

	const viewProps = {
		...props,
		onBalanceAction: onBalanceAction,
	};

	return (
		<BreakpointWrapper
			desktopView={<BalanceCardView {...viewProps} isDesktop />}
			mobileView={<BalanceCardView {...viewProps} />}
		/>
	);
};
