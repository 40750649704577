import { registrationErrors } from "@app/constants/api-errors";
import { paths } from "@app/constants/paths";
import { useRegister } from "@app/helpers";
import type { MappedReasons } from "@app/services";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { type FormValues, RegisterView } from "./register-view";

interface RegisterState {
	captchaLoaded: boolean;
	captchaToken: string;
	didSubmit: boolean;
	error?: string;
	mappedReasons?: MappedReasons;
	formSubmitted: boolean;
	openModal: boolean;
	stepNumber: number;
	validate: boolean;
}

const Register = () => {
	const navigate = useNavigate();
	const [register] = useRegister();

	const defaultState: RegisterState = {
		captchaLoaded: false,
		captchaToken: "",
		didSubmit: false,
		formSubmitted: false,
		openModal: false,
		stepNumber: 0,
		validate: false,
	};
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState<RegisterState>(defaultState);

	const onChangeCaptcha = (token?: string | null) => {
		let tokenToUse = "";

		if (!token || token.length === 0) {
			tokenToUse = "";
		} else {
			tokenToUse = token;
		}

		setState({ ...state, captchaToken: tokenToUse });
	};

	const onCloseExistsModal = () => {
		setState({ ...state, openModal: false });
	};

	const onLoadCaptcha = () => {
		setState({ ...state, captchaLoaded: true });
	};

	const onLogin = () => {
		navigate(paths.login());
	};

	const onSubmit = (values: FormValues) => {
		if (values.email && values.password) {
			setIsLoading(true);
			register(
				values.email,
				values.password,
				(errors?: string[], mappedReasons?: MappedReasons) => {
					setIsLoading(false);
					if (!errors) {
						setState({
							...state,
							didSubmit: true,
							error: undefined,
							mappedReasons: undefined,
							stepNumber: state.stepNumber + 1,
							formSubmitted: true,
						});
					} else {
						setState({
							...state,
							didSubmit: true,
							error: errors.join(" "),
							mappedReasons: mappedReasons,
							openModal: errors.includes(registrationErrors.duplicateAccount),
						});
					}
				},
			);
		}
	};

	const onClearErrors = (name?: string) => {
		if (name) {
			const newMappedReasons = { ...state.mappedReasons };
			newMappedReasons[name] = undefined;
			setState({ ...state, mappedReasons: newMappedReasons });
		} else setState({ ...state, mappedReasons: undefined });
	};

	const onValidate = (errors: FormValues, submit: () => void) => {
		if (
			Object.keys(errors).length > 0 ||
			!state.captchaToken ||
			(state.captchaToken && state.captchaToken.length < 1)
		) {
			setState({ ...state, didSubmit: true, validate: true });
			return;
		}
		submit();
	};

	const viewProps = {
		captchaKey: "6Lc_4g0qAAAAAJV2EptgX97lmMkgOwyyd1hq4SAy",
		captchaLoaded: state.captchaLoaded,
		captchaToken: state.captchaToken,
		didSubmit: state.didSubmit,
		error: state.error,
		mappedReasons: state.mappedReasons,
		formSubmitted: state.formSubmitted,
		openModal: state.openModal,
		step: state.stepNumber,
		validate: state.validate,
		onChangeCaptcha: onChangeCaptcha,
		onCloseExistsModal: onCloseExistsModal,
		onLoadCaptcha: onLoadCaptcha,
		onLogin: onLogin,
		onSubmit: onSubmit,
		onClearErrors: onClearErrors,
		onValidate: onValidate,
		isLoading,
	};

	return (
		<BreakpointWrapper
			desktopView={<RegisterView {...viewProps} />}
			mobileView={<RegisterView {...viewProps} isCompact />}
			mobileBreakpoint={300}
		/>
	);
};

export default Register;
