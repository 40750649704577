import { CustomLoader } from "@app/components/custom-loader";
import { Button } from "@app/controls/button";
import { Paginator } from "@app/controls/paginator";
import type { RootState } from "@app/redux";
import { getColourValue } from "@app/utils/get-colour-value";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import messages from "./messages";
import type { ViewProperties } from "./properties";
import "./view-statement-modal.css";
import { FiDownload } from "react-icons/fi";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ViewStatementModalView = React.memo((props: ViewProperties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const downloaderStyle = "shrink-0 grow-0 p-0 mr-5 icon-button";
	const modalStyle = ["py-6", "view-statement-modal"].join(" ");
	const titleContentStyle = [
		"pl-3",
		"truncate",
		"title",
		"flex",
		"flex-row",
		"gap-x-2",
		"justify-between",
		"w-full",
	].join(" ");

	const spacingWidth = React.useMemo(() => {
		return +getColourValue("--px-34").replace("rem", "") * 16 * 2;
	}, []);

	const getDocumentContent = () => {
		return (
			<Document
				loading={<CustomLoader page theme="light" />}
				file={props.fileData}
				onLoadSuccess={({ numPages }) => props.onSetPageCount(numPages)}
			>
				<Page
					pageNumber={props.pageSelected}
					width={props.windowWidth - spacingWidth}
				/>
			</Document>
		);
	};

	const getFooterContent = () => {
		return (
			<div className="flex flex-row justify-center my-6">
				<Paginator
					currentPage={props.pageSelected}
					totalPages={props.pageCount}
					onNavigatePage={props.onChangePage}
					showFixedFirstPage
					showFixedLastPage
				/>
			</div>
		);
	};

	const getTitleContent = () => {
		return props.fileName !== undefined ? (
			<div className={titleContentStyle}>
				<div className="my-auto truncate">
					<span className="font-primary-regular">
						{messages[language].header}
					</span>
					<span className="font-primary-medium">{props.fileName}</span>
				</div>
				<Button
					className={downloaderStyle}
					onClick={() => props.tradeId && props.onDownload(props.tradeId)}
				>
					<FiDownload className="icon-outlined" size="32px" />
				</Button>
			</div>
		) : undefined;
	};

	return (
		<Modal
			className={modalStyle}
			footerContent={getFooterContent()}
			open={props.open}
			titleContent={getTitleContent()}
			onClose={props.onClose}
		>
			{getDocumentContent()}
		</Modal>
	);
});

export { ViewStatementModalView };
