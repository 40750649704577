import { dateFormats } from "@app/constants/date-formats";
import type {
	ArbitragerSpreads,
	ChartType,
	DataSet,
	SpreadResponse,
} from "@app/entities";
import type { RootState } from "@app/redux";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import { snapNumber } from "@app/utils/snap-number";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import type { TooltipItem } from "chart.js";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import messages from "./messages";
import type { SpreadGraphCardState } from "./models/spread-graph-card-state";
import type { Properties, ViewProperties } from "./properties";
import { SpreadGraphCardView } from "./spread-graph-card-view";

const SpreadGraphCard = (props: Properties) => {
	const { currentPeriod, historySpread, historySpreadLoading, periodSettings } =
		useSelector((rootState: RootState) => rootState.arbitrager);

	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const now = React.useMemo(() => {
		return new Date();
	}, []);

	const intervalIndex = React.useMemo(() => {
		return periodSettings
			? Math.max(
					periodSettings?.marketChart.periods.findIndex(
						(value) => (value.days ?? 0) === (currentPeriod?.days ?? 0),
					),
					0,
				)
			: 0;
	}, []);

	const defaultState: SpreadGraphCardState = {
		arbitrageBreakdown: "SPREAD",
		intervalIndex: intervalIndex,
	};

	const [state, setState] = React.useState<SpreadGraphCardState>(defaultState);

	// Separated from main state to avoid race condition when doing initial mapping
	const [canChangePeriod, setCanChangePeriod] = React.useState(false);

	const defaultGraph = React.useMemo(() => {
		return {
			data: {
				data: [...Array(100)].map((x) => Math.random() * 5),
				label: messages[language].default.dataSet,
			},
			labels: [...Array(100)].map((x) => messages[language].default.date),
			labelsFull: [...Array(100).map((x) => messages[language].default.date)],
		};
	}, []);

	const getBreakdownValue = (value: SpreadResponse) => {
		switch (state.arbitrageBreakdown) {
			case "EXCHANGE_RATE": {
				return value.exchangeRate;
			}
			case "LOCAL_PRICE": {
				return value.localPrice;
			}
			case "OFFSHORE_PRICE": {
				return value.offshorePrice;
			}
			case "SPREAD":
			default: {
				return value.spread;
			}
		}
	};

	const mapBreakdown = () => {
		const chartData: DataSet = { data: [], label: "" };

		const label = "";

		chartData.data = historySpread.map((x) => getBreakdownValue(x));
		chartData.label = "";

		let chartLabels: string[];

		const timezoneOffset = now.getTimezoneOffset();

		chartLabels = historySpread.map((x) => {
			const dataDate = moment(x.datetime, dateFormats.iso8601withTime).subtract(
				timezoneOffset,
				"minutes",
			);

			if (currentPeriod?.days === 1) {
				return dataDate
					.clone()
					.set("hours", snapNumber(dataDate.hours(), 1))
					.set("minutes", snapNumber(dataDate.minutes(), 60))
					.format(dateFormats.time24HoursMinutes);
			} else {
				return dataDate.format(dateFormats.iso8601withTime);
			}
		});

		let chartLabelsFull: string[];

		chartLabelsFull = historySpread.map((x) => {
			const dataDate = moment(x.datetime, dateFormats.iso8601withTime);
			return dataDate.format(dateFormats.iso8601withTime);
		});

		setState({
			...state,
			chartData: chartData,
			chartLabels: chartLabels,
			chartLabelsFull: chartLabelsFull,
		});
	};

	const onChange = (period: string) => {
		setState({
			...state,
			intervalIndex: +period,
		});
	};

	const onChangeBreakdown = (value: string) => {
		const valueAsArbitrager = value as ArbitragerSpreads;
		if (valueAsArbitrager && valueAsArbitrager.length > 0) {
			setState({
				...state,
				arbitrageBreakdown: valueAsArbitrager,
			});
		}
	};

	const upperLimit = periodSettings
		? periodSettings?.marketChart.periods.length - 1
		: 0;

	const onRenderTooltipLabel = (
		tooltip: TooltipItem<ChartType>,
		formatOptions: FormatNumberOptions,
	) => {
		const data =
			historySpread && historySpread.length > 0
				? state.chartData
				: props.chartData ?? defaultGraph.data;

		return data
			? `${formatNumber(tooltip.parsed.y, formatOptions ?? undefined)}`
			: `${tooltip.dataset.label ?? ""}: ${tooltip.parsed.y}`;
	};

	const onRenderTooltipTitle = (tooltips: TooltipItem<ChartType>[]) => {
		const data =
			historySpread && historySpread.length > 0
				? state.chartLabelsFull
				: props.chartLabelsFull ?? defaultGraph.labelsFull;

		return data && data.length > 0 && tooltips.length > 0
			? moment(
					data[Math.max(0, tooltips[0].dataIndex)],
					dateFormats.iso8601withTime,
				)
					.subtract(now.getTimezoneOffset(), "minutes")
					.format(dateFormats.iso8601withTime)
			: messages[language].default.date;
	};

	React.useEffect(() => {
		if (canChangePeriod) {
			if (props.onChangePeriod) {
				props.onChangePeriod(
					periodSettings?.marketChart.periods[state.intervalIndex],
				);
			}
		}
	}, [state.intervalIndex]);

	React.useEffect(() => {
		mapBreakdown();
		const timeout = setTimeout(() => {
			setCanChangePeriod(true);
		}, 500);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	React.useEffect(() => {
		mapBreakdown();
	}, [historySpread, state.arbitrageBreakdown, currentPeriod]);

	const viewProps: ViewProperties = {
		...props,
		breakdown: state.arbitrageBreakdown,
		chartData:
			historySpread && historySpread.length > 0
				? state.chartData
				: props.chartData ?? defaultGraph.data,
		chartLabels:
			historySpread && historySpread.length > 0
				? state.chartLabels
				: props.chartLabels ?? defaultGraph.labels,
		chartLabelsFull:
			historySpread && historySpread.length > 0
				? state.chartLabelsFull
				: props.chartLabelsFull ?? defaultGraph.labelsFull,
		currentPeriod: currentPeriod,
		intervalIndex: state.intervalIndex,
		loading: props.loading || historySpreadLoading,
		periodSettings: periodSettings,
		onChange: onChange,
		onChangeBreakdown: onChangeBreakdown,
		onRenderTooltipLabel: onRenderTooltipLabel,
		onRenderTooltipTitle: onRenderTooltipTitle,
	};

	return (
		<BreakpointWrapper
			desktopView={<SpreadGraphCardView {...viewProps} isDesktop />}
			mobileView={<SpreadGraphCardView {...viewProps} />}
		/>
	);
};

export { SpreadGraphCard };
