import background from "@app/assets/images/background.jpg";
import logo from "@app/assets/images/logo-home.svg";
import { HasAccountModal } from "@app/components/modals/has-account-modal";
import { Wizard } from "@app/components/wizard";
import { links } from "@app/constants/links";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./register.css";

import type { MappedReasons } from "@app/services";

export type FormValues = {
	email?: string;
	password?: string;
	confirmPassword?: string;
};

export const RegisterView = React.memo(
	(props: {
		captchaKey: string;
		captchaLoaded: boolean;
		captchaToken: string;
		didSubmit: boolean;
		error?: string;
		mappedReasons?: MappedReasons;
		formSubmitted: boolean;
		isCompact?: boolean;
		openModal: boolean;
		step: number;
		isLoading: boolean;
		validate: boolean;
		onChangeCaptcha: (token?: string | null) => void;
		onCloseExistsModal: () => void;
		onLogin: () => void;
		onLoadCaptcha: () => void;
		onSubmit: (values: FormValues) => void;
		onClearErrors: (name?: string) => void;
		onValidate: (errors: FormValues, submit: () => void) => void;
	}) => {
		const captchaStyle = [
			props.didSubmit && !props.captchaToken && "border",
			"2xl:mb-3.5",
			"pl-0.5",
			"rounded",
			"register-recaptcha",
			props.didSubmit && !props.captchaToken && "register-recaptcha-error",
		].join(" ");

		const getErrorContent = (showCondition: boolean, content: string) => (
			<Transition
				show={showCondition}
				enter="transition ease-out duration-300"
				leave="transition east-out duration-300"
				enterFrom="opacity-0 translate-y-5"
				enterTo="opacity-100 translate-y-0"
				leaveTo="opacity-0 -translate-y-5"
			>
				<div className="flex flex-col gap-y-2">
					<div className="font-primary-regular register-error">{content}</div>
				</div>
			</Transition>
		);

		const headingSeparator = (className?: string) => (
			<div
				className={[
					"rounded-full w-24 register-heading-separator",
					className,
				].join(" ")}
			/>
		);

		const passwordsDoNotMatch = (
			password?: string,
			confirmPassword?: string,
		) => {
			return (
				!isEmpty(password) &&
				!isEmpty(confirmPassword) &&
				password !== confirmPassword
			);
		};

		const isFieldInError = (key: string): boolean | undefined => {
			let result = false;
			if (props.mappedReasons)
				result = (props.mappedReasons[key] ?? []).length > 0;
			if (!result) return undefined;
			return result;
		};

		const formStep1 = (
			<div className="flex flex-col flex-1 2xl:mt-4">
				<Formik
					initialValues={{
						password: "",
						confirmPassword: "",
						email: "",
					}}
					onSubmit={async (values) => {
						props.onSubmit(values);
					}}
				>
					{({ values, handleSubmit, validateForm, errors }) => {
						const passwordCheck = passwordsDoNotMatch(
							values.password,
							values.confirmPassword,
						);

						return (
							<>
								<h2 className="font-secondary-regular py-0 -mb-3 register-heading">
									Register
								</h2>
								{headingSeparator("my-3 2xl:my-6")}
								<div className="flex flex-col h-full items-start 2xl:pt-3 w-full">
									{getErrorContent(
										errors.email !== undefined && props.validate,
										"Please enter a valid email address",
									)}
									{getError("email")}
									<Input
										className="mb-2 2xl:mb-4 w-full py-1 2xl:py-2.5 register-input"
										maxLength={150}
										name="email"
										placeholder="Email *"
										title="Please enter your email address"
										type="email"
										required
										useFormik
										validateOnChange={props.validate}
										error={isFieldInError("email")}
										onChange={() => props.onClearErrors("email")}
									/>
									{getError("password")}
									<Input
										className="mb-2 2xl:mb-4 w-full py-1 2xl:py-2.5 register-input"
										hideIcon
										name="password"
										peekablePassword
										placeholder="Password *"
										required
										title="Please enter your password"
										type="password"
										useFormik
										validateOnChange={props.validate}
										error={passwordCheck || isFieldInError("password")}
										onChange={() => props.onClearErrors("password")}
									/>
									{getError("confirmPassword")}
									<Input
										className="mb-2 2xl:mb-4 w-full py-1 2xl:py-2.5 register-input"
										hideIcon
										name="confirmPassword"
										peekablePassword
										placeholder="Confirm Password *"
										title="Please re-enter your password"
										type="password"
										required
										useFormik
										validateOnChange={props.validate}
										error={passwordCheck || isFieldInError("confirmPassword")}
									/>
									{getErrorContent(
										!!passwordCheck,
										"Both passwords must match",
									)}
									{props.captchaKey && props.captchaKey.length > 0 && (
										<div
											className={captchaStyle}
											style={{ marginTop: passwordCheck ? "12px" : 0 }}
										>
											<ReCAPTCHA
												asyncScriptOnLoad={props.onLoadCaptcha}
												sitekey={props.captchaKey}
												onChange={props.onChangeCaptcha}
												size={props.isCompact ? "compact" : "normal"}
											/>
										</div>
									)}
									<Button
										className="leading-none text-xl w-full register-button font-primary-bold"
										disabled={!props.captchaLoaded || props.isLoading}
										theme="primary"
										onClick={async () =>
											props.onValidate(await validateForm(), handleSubmit)
										}
									>
										<div>SUBMIT</div>
									</Button>
									<div className="font-primary-regular mt-2 2xl:mt-4 register-agreement">
										<label className="register-terms">
											By registering I agree to the{" "}
											<a
												href={links.legal.termsAndConditions}
												target="_blank"
												rel="noreferrer"
											>
												Terms and Conditions
											</a>{" "}
											and processing of my personal data as stated in the{" "}
											<a
												href={links.legal.privacyPolicy}
												target="_blank"
												rel="noreferrer"
											>
												Privacy Policy
											</a>
										</label>
									</div>
								</div>
							</>
						);
					}}
				</Formik>
			</div>
		);

		const formComplete = (
			<div
				className={[
					"flex flex-col flex-1 2xl:mt-4",
					"gap-y-4",
					"items-center",
				].join(" ")}
			>
				<h3 className="font-secondary-regular py-0 -mb-3 register-heading text-center">
					Thank you for registering
				</h3>
				<h2 className="font-secondary-regular leading-none mb-4 register-subheading text-center">
					A verification link has been sent to the email address you provided.
				</h2>
			</div>
		);

		const getError = (fieldName: string) => {
			let errors = "";
			if (props.mappedReasons)
				if (props.mappedReasons[fieldName] !== undefined)
					errors += (props.mappedReasons[fieldName] || [])
						.map((x) => `<p>${x}</p>`)
						.join("");

			if (!errors) return undefined;

			return (
				<div
					className={[
						"flex justify-center register-error",
						props.mappedReasons && "pb-2 pt-0.5",
					].join(" ")}
				>
					<span>
						<Transition
							enter="transition ease-out duration-300"
							enterFrom="opacity-0 translate-y-5"
							leave="transition ease-out duration-300"
							leaveTo="opacity-0 -translate-y-5"
							dangerouslySetInnerHTML={{
								__html: errors,
							}}
						/>
					</span>
				</div>
			);
		};

		return (
			<>
				{props.openModal && (
					<HasAccountModal
						open
						onLogin={props.onLogin}
						onClose={props.onCloseExistsModal}
					/>
				)}
				<div className="flex flex-col lg:flex-row lg:h-screen">
					<div
						className={[
							"flex flex-col grow lg:overflow-y-auto w-full",
							"h-full",
						].join(" ")}
					>
						<a
							className="my-2 p-4 group w-fit"
							href={links.home}
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="group-hover:sm:ml-1 object-contain w-fit register-home"
								src={logo}
								alt="Home"
							/>
						</a>
						<div className="flex flex-col my-auto mx-auto px-6 sm:px-0 w-full sm:w-4/5 md:w-9/12 lg:w-3/5 register-form-body">
							<Wizard
								completeForm={formComplete}
								className="w-full"
								forms={[formStep1]}
								isComplete={props.formSubmitted}
								step={props.step}
								hideSteps={props.step === 0}
							/>
						</div>
					</div>
					<div className="flex flex-col grow lg:overflow-y-auto w-full lg:h-full">
						<div
							className="bg-cover bg-center flex flex-col justify-center items-center h-full px-6 py-24 lg:py-0 register-background"
							style={{ backgroundImage: `url(${background})` }}
						>
							<div className="font-secondary-regular leading-none -mb-1 text-center register-login-heading">
								Already have an account?
							</div>
							{headingSeparator("my-6")}
							<Button
								className="font-primary-bold grow-0 min-w-fit py-5 px-5 text-lg uppercase w-4/12"
								theme="primary"
								onClick={props.onLogin}
							>
								LOGIN
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	},
);
