import type { FiaDetails, SdaDetails } from "@app/entities";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import { AllowanceCardView } from "./allowance-card-view";

export type AllowanceType = "fia" | "sda";
export interface Allowance {
	allowanceType?: AllowanceType;
	heading: string;
	valueLower: number;
	valueUpper: number;
}

export const AllowanceCard = (props: {
	mobile?: boolean;
	allowances?: Allowance[];
	fiaDetails?: FiaDetails | null;
	sdaDetails?: SdaDetails | null;
	className?: string;
}) => {
	const getFiaDetails = () => {
		if (props.fiaDetails)
			return [
				{
					amount: props.fiaDetails.locked.amount,
					label: props.fiaDetails.locked.label,
					colourClass: "bar-graph-popover-colour-one",
					order: 0,
				},
				{
					amount: props.fiaDetails.pending.amount,
					label: props.fiaDetails.pending.label,
					colourClass: "bar-graph-popover-colour-two",
					order: 1,
				},
				{
					amount: props.fiaDetails.available.amount,
					label: props.fiaDetails.available.label,
					colourClass: "bar-graph-popover-colour-three",
					order: 2,
				},
			];
	};

	const getSdaDetails = () => {
		if (props.sdaDetails)
			return [
				{
					amount: props.sdaDetails.reserved,
					label: "Reserved",
					colourClass: "bar-graph-popover-colour-one",
					order: 0,
				},
				{
					amount: props.sdaDetails.sdaUnused,
					label: "Available",
					colourClass: "bar-graph-popover-colour-two",
					order: 1,
				},
				{
					amount: props.sdaDetails.sdaUsed,
					label: "",
					colourClass: "",
					order: 2,
				},
			];
	};

	const viewProps = {
		...props,
		getFiaDetails,
		getSdaDetails,
	};
	return (
		<BreakpointWrapper
			desktopView={<AllowanceCardView {...viewProps} />}
			mobileView={<AllowanceCardView {...viewProps} mobile />}
		/>
	);
};
