import type { RootState } from "@app/redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalView } from "./modal-view";
import type { Properties } from "./properties";

export const Modal = (props: Properties) => {
	const { tokenHasExpired, userIsAuthenticated } = useSelector(
		(rootState: RootState) => rootState.auth,
	);

	const [openState, setOpenState] = useState(false);

	useEffect(() => {
		if (openState !== props.open) setOpenState(props.open);
	}, [props.open]);

	const onClose = () => {
		setOpenState(false);
		setTimeout(() => {
			props.onClose?.();
		}, 300);
	};

	const canOpen = () => {
		return !props.ignoreTokenExpiry
			? tokenHasExpired !== undefined
				? openState && userIsAuthenticated === true && tokenHasExpired === false
				: openState
			: openState;
	};

	return <ModalView {...props} open={canOpen()} onClose={onClose} />;
};
