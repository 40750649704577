import { Paginator } from "@app/controls/paginator";
import React from "react";
import type { Cell } from "./models/cell";
import type { Row } from "./models/row";
import type { Properties } from "./properties";
import "./table.css";

export const TableView = React.memo((props: Properties) => {
	const getClassNames = () => {
		const classesToUse = ["flex flex-col flex-1"];

		if (props.className) classesToUse.push(props.className);

		return classesToUse.join(" ");
	};

	const buildRow = (row: Row, index: number, isHeader?: boolean) => {
		const classNames = [];

		if (row.onClick) classNames.push("clickable");

		if (isHeader) classNames.push("border-t-0");

		return (
			<tr className={classNames.join(" ")} onClick={row.onClick} key={index}>
				{row.cells.map((x, cellIndex) => buildCell(x, cellIndex, isHeader))}
			</tr>
		);
	};

	const buildCell = (cell: Cell, index: number, isHeader?: boolean) => {
		var classesToUse = ["text-left px-5 py-4 lg:px-14 lg:py-9"];

		if (cell.alignment === "left")
			classesToUse = ["text-left pr-10 py-4 lg:pr-28 lg:py-9"];

		if (cell.className) classesToUse.push(cell.className);

		if (isHeader)
			return (
				<th key={index} className={classesToUse.join(" ")}>
					{cell.content}
				</th>
			);

		return (
			<td key={index} onClick={cell.onClick} className={classesToUse.join(" ")}>
				{cell.content}
			</td>
		);
	};

	const buildPaginator = () => {
		return (
			<div className="paginator flex my-9 justify-center">
				<Paginator
					blockSize={props.blockSize}
					currentPage={props.currentPage || 1}
					showFixedFirstPage
					showFixedLastPage
					totalPages={props.totalPages || 1}
					onNavigatePage={(page) =>
						props.onNavigatePage && props.onNavigatePage(page)
					}
				/>
			</div>
		);
	};

	return (
		<div className={getClassNames()}>
			<div className="overflow-auto">
				<table className="w-full mb-2" cellPadding={0}>
					<thead>{buildRow(props.headers, 0, true)}</thead>
					<tbody>
						{props.data.map((x, rowIndex) => buildRow(x, rowIndex))}
					</tbody>
				</table>
			</div>
			{props.pagination && buildPaginator()}
		</div>
	);
});
