import { ArbitragerUpdater } from "@app/components/arbitrager-updater";
import { CustomLoader } from "@app/components/custom-loader";
import { ThrottleManager } from "@app/components/throttle-manager";
import { store, storePersist } from "@app/redux";
import routes, { type Route as LocalRoute } from "@app/routes";
import { joinUrl } from "@app/utils/join-url";
import { Auth as AuthWrapper } from "@app/wrappers/auth";
import { ClientWrapper } from "@app/wrappers/client-wrapper";
import { SessionWrapper } from "@app/wrappers/session-wrapper";
import type React from "react";
import { Suspense } from "react";
import { Provider } from "react-redux";
import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ScrollResetter } from "./components/scroll-resetter";

const renderRoute = (route: LocalRoute, parentPath = "") => {
	const component = (
		<route.component>
			{route.routes?.map((r) => renderRoute(r, route.path))}
		</route.component>
	);

	const allowConditionalWrappers = !route.public && !parentPath;

	const emptyWrapper = ({ children }: { children: React.ReactNode }) => (
		<>{children}</>
	);

	const Wrapper = allowConditionalWrappers ? AuthWrapper : emptyWrapper;

	const WrapperClient = allowConditionalWrappers ? ClientWrapper : emptyWrapper;

	return (
		<Route
			key={route.path}
			path={joinUrl(parentPath, route.path)}
			caseSensitive={false}
			element={
				<SessionWrapper>
					<Wrapper>
						<WrapperClient>
							<ThrottleManager />
							<Suspense fallback={<CustomLoader page size="large" />}>
								{component}
							</Suspense>
						</WrapperClient>
					</Wrapper>
				</SessionWrapper>
			}
		/>
	);
};

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={storePersist}>
			<ArbitragerUpdater />
			<Router>
				<ScrollResetter />
				<Routes>
					{routes.map((route: LocalRoute) => renderRoute(route))}
					<Route path="/404" />
					<Route path="*" element={<Navigate to="/404" replace />} />
				</Routes>
			</Router>
		</PersistGate>
	</Provider>
);

export default App;
