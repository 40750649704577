import { apiErrors } from "@app/constants/api-errors";
import { useLogout } from "@app/helpers";
import type { RootState } from "@app/redux";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import React from "react";
import { useSelector } from "react-redux";
import messages from "./messages";
import type { SidebarState } from "./models/sidebar-state";
import type { Properties } from "./properties";
import { SidebarView } from "./sidebar-view";

export const Sidebar = (props: Properties) => {
	const { activeClient, clients } = useSelector(
		(state: RootState) => state.clients,
	);

	const { profile } = useSelector((state: RootState) => state.auth);

	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const [logout] = useLogout();

	const defaultState: SidebarState = {
		showLoadingModal: false,
		showLogoutModal: false,
		showNewClientModal: false,
	};

	const [state, setState] = React.useState<SidebarState>(defaultState);

	const getProperties = () => {
		return {
			...props,
			open: props.open || false,
			userEmail: profile?.email || messages[language].default.emailAddress,
			clientName: activeClient
				? `${activeClient.firstName} ${activeClient.lastName}`
				: messages[language].default.clientName,
			manager:
				activeClient && activeClient.relationshipManager
					? {
							firstName: activeClient.relationshipManager.firstName,
							lastName: activeClient.relationshipManager.lastName,
							email: activeClient.relationshipManager.email,
							contactNumber: activeClient.relationshipManager.contactNumber,
							picture: activeClient.relationshipManager.picture,
						}
					: {
							firstName: messages[language].default.rmFirstName,
							lastName: messages[language].default.rmLastName,
							email: messages[language].default.email,
							contactNumber: messages[language].default.contactNumber,
							picture: null,
						},
			showAddClient: clients.length < 2,
			showLoadingModal: state.showLoadingModal,
			showLogoutModal: state.showLogoutModal,
			showNewClientModal: state.showNewClientModal,
			onClose: onClose,
			onCloseLogoutModal: onCloseLogoutModal,
			onLogout: onLogout,
			onToggleNewClientModal: onToggleNewClientModal,
		};
	};

	const onClose = () => {
		if (props.onClose) props.onClose();
	};

	const onLogout = () => {
		logout((errors?: string[]) => {
			const showLogout = !(
				errors &&
				(errors.includes(apiErrors.tokenExpired) ||
					errors.includes(apiErrors.invalidToken))
			);
			setState({ ...state, showLogoutModal: showLogout });
		});
	};

	const onCloseLogoutModal = () => {
		setState({ ...state, showLogoutModal: false });
	};

	const onToggleNewClientModal = (toggle: boolean) => {
		setState({ ...state, showNewClientModal: toggle });
		if (toggle && props.onClose) props.onClose();
	};

	const viewProps = getProperties();

	return (
		<BreakpointWrapper
			desktopView={<SidebarView {...viewProps} open={true} />}
			mobileView={<SidebarView {...viewProps} mobile />}
		/>
	);
};
