const messages = {
	en: {
		currencyCode: "R ",
		defaultValue: "R -",
		groupSeparator: ",",
		minimumReturn: "Minimum Return",
		perCycle: "Per Cycle",
		footnote:
			"A Minimum Return will only show once a trade is loaded or in progress. If you'd like to get a trade loaded, speak to your Relationship Manager.",
		blankText: "(Why is this blank?)",
	},
};

export default messages;
