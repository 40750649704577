import { ClientAccordion } from "@app/components/client-accordion";
import { CustomLoader } from "@app/components/custom-loader";
import { Footer } from "@app/components/footer";
import { NewClientModal } from "@app/components/modals/new-client-modal";
import { StatusModal } from "@app/components/modals/status-modal";
import { Navbar } from "@app/components/navbar";
import { Popover } from "@app/components/popovers/popover";
import { Status } from "@app/components/status";
import { type Row, Table } from "@app/components/table";
import type { TradeStage } from "@app/constants/trade-stage";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import { Paginator } from "@app/controls/paginator";
import type { ClientSummary } from "@app/entities";
import type { RootState } from "@app/redux";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import { type ReactNode, memo } from "react";
import { useSelector } from "react-redux";
import "./clients.css";
import { FaInfoCircle } from "react-icons/fa";

const messages = {
	en: {
		defaultValue: "R 0",
		defaultValueMobile: "R 0",
		balanceFootnote:
			"Note that this is an <strong>approximate</strong> balance based on your recent trades. It may be inaccurate if you have recently deposited or withdrawn funds.",
		minimumReturnFootnote:
			"A Minimum Return will only show once a trade is loaded or in progress. If you'd like to get a trade loaded, speak to your Relationship Manager.",
		groupSeparator: ",",
		heading: "Clients",
		search: "Search",
		subHeading: "Client",
		table: {
			allowanceAvailable: "Allowance Available",
			balance: "Balance",
			client: "Client",
			minReturn: "Min Return",
			totalProfit: "Total Profit",
			tradeStatus: "Trade Status",
		},
	},
};

const ClientsView = memo(
	(props: {
		clients: ClientSummary[];
		isDesktop?: boolean;
		loading?: boolean;
		page: number;
		pageCount: number;
		reduxErrors?: string;
		searchQuery?: string;
		showNewClientModal: boolean;
		showReduxErrors: boolean;
		onClearErrors: () => void;
		onToggleNewClientModal: () => void;
		onNavigatePage: (page: number) => void;
		onSearch: (value: string) => void;
		onViewClient: (identifier: number) => void;
	}) => {
		const { language } = useSelector(
			(rootState: RootState) => rootState.language,
		);

		const renderTooltip = (footnote: string, styleOverride?: string) => {
			return (
				<Popover
					className={styleOverride}
					toggleContent={
						<FaInfoCircle className="opacity-50 clients-icon" size="16px" />
					}
					popperContent={
						<div
							className="font-primary-regular leading-5 -m-2.5 p-2.5 clients-footnote"
							dangerouslySetInnerHTML={{
								__html: footnote,
							}}
						/>
					}
					showArrow={true}
					showOnHover={props.isDesktop ? true : undefined}
					placement={props.isDesktop ? "right-start" : "bottom-end"}
					offset={props.isDesktop ? [8, 16] : [0, 16]}
				/>
			);
		};

		const getFormattedClientSummary = (client: ClientSummary) => {
			const currencyOptions: FormatNumberOptions = {
				currencyCode: "R ",
				decimalPlaces: props.isDesktop ? 2 : 0,
				groupSeparator: messages[language].groupSeparator,
			};

			const percentageOptions: FormatNumberOptions = {
				decimalPlaces: 2,
				groupSeparator: messages[language].groupSeparator,
				isPercentage: true,
			};

			const defaultValue = props.isDesktop
				? messages[language].defaultValue
				: messages[language].defaultValueMobile;

			const fundsAvailable = client.fundsAvailable
				? formatNumber(client.fundsAvailable ?? 0, currencyOptions)
				: defaultValue;

			const fundsLastUpdatedString = client.fundsLastUpdated?.string;

			const minimumReturn = client.minimumReturn
				? formatNumber(client.minimumReturn ?? 0, percentageOptions)
				: "- %";

			const allowance =
				(client.allowanceAvailable?.fia ?? 0) +
				(client.allowanceAvailable?.sda ?? 0);

			const allowanceAvailable = allowance
				? formatNumber(allowance, currencyOptions)
				: defaultValue;

			const totalProfit = client.totalProfit
				? formatNumber(client.totalProfit ?? 0, currencyOptions)
				: defaultValue;

			const tradeStage = (client.tradeStatus?.position || 0) as TradeStage;
			const tradeStatus = client.tradeStatus?.label;

			return {
				firstName: client.firstName,
				lastName: client.lastName,
				fundsAvailable: fundsAvailable,
				fundsLastUpdatedString: fundsLastUpdatedString,
				minimumReturn: minimumReturn,
				allowanceAvailable: allowanceAvailable,
				totalProfit: totalProfit,
				tradeStage: tradeStage,
				tradeStatus: tradeStatus,
			};
		};

		const getStatusPopover = (
			stage: TradeStage,
			status?: string,
			mobile?: boolean,
		) => {
			return (
				<>
					<Popover
						className={["w-full", mobile && "text-right"].join(" ")}
						toggleContent={
							<div className="p-4">
								<Status
									className={mobile ? undefined : "border clients-status-bar"}
									stage={stage}
									totalStages={3}
									type={mobile ? "circle" : "bar"}
								/>
							</div>
						}
						popperContent={
							status && (
								<div className="-m-2.5 p-2.5 clients-trade-info">
									<span className="font-primary-medium clients-trade-info-inner">
										{status}
									</span>
								</div>
							)
						}
						showArrow={true}
						showOnHover={mobile ? undefined : true}
						placement={"left"}
					/>
				</>
			);
		};

		const getTableHeadersContent = (): Row => {
			return {
				cells: [
					{
						content: (
							<div className="font-primary-medium clients-table-header">
								{messages[language].table.client}
							</div>
						),
					},
					{
						content: (
							<div className="flex flex-row gap-x-2">
								<div className="font-primary-medium clients-table-header">
									{messages[language].table.balance}
								</div>
							</div>
						),
					},
					{
						content: (
							<div className="font-primary-medium clients-table-header">
								{messages[language].table.minReturn}
							</div>
						),
					},
					{
						content: (
							<div className="font-primary-medium clients-table-header">
								{messages[language].table.allowanceAvailable}
							</div>
						),
					},
					{
						content: (
							<div className="font-primary-medium clients-table-header">
								{messages[language].table.totalProfit}
							</div>
						),
					},
					{
						content: (
							<div className="font-primary-medium clients-table-header">
								{messages[language].table.tradeStatus}
							</div>
						),
					},
				],
			};
		};

		const getMinimumReturnContent = (minimumReturn: string): ReactNode => {
			const defaultPercent = minimumReturn === "- %";

			return defaultPercent ? (
				<div className={"clients-percent-tooltip-container"}>
					<div className={"clients-percent"}>{minimumReturn}</div>
					{renderTooltip(messages[language].minimumReturnFootnote, "z-20")}
				</div>
			) : (
				minimumReturn
			);
		};

		const getTableRow = (item: ClientSummary): Row => {
			const client = getFormattedClientSummary(item);

			return {
				cells: [
					{
						content: (
							<div className="font-primary-regular inline whitespace-nowrap clients-table-name">
								{`${client.lastName.trim() ? `${client.lastName.trim()}, ` : ""} ${client.firstName.trim()}`.trim()}
							</div>
						),
					},
					{
						content: (
							<div className="flex whitespace-nowrap">
								{client.fundsAvailable}
								{client.fundsLastUpdatedString &&
									renderTooltip(client.fundsLastUpdatedString, "z-30 ml-2")}
							</div>
						),
					},
					{
						content: getMinimumReturnContent(client.minimumReturn),
					},
					{
						className: "whitespace-nowrap",
						content: client.allowanceAvailable,
					},
					{
						className: "whitespace-nowrap",
						content: client.totalProfit,
					},
					{
						content: getStatusPopover(
							client.tradeStage,
							client.tradeStatus ?? "",
						),
					},
				],
				onClick: () => props.onViewClient(item.id),
			};
		};

		const getTableDataContent = (): Row[] =>
			props.clients.map((x) => getTableRow(x));

		return (
			<>
				<>
					{props.loading && <CustomLoader page />}
					{props.showNewClientModal && (
						<NewClientModal open onClose={props.onToggleNewClientModal} />
					)}
					<div className="min-h-screen h-full clients-container">
						<Navbar />
						<div className="flex flex-col gap-y-3 grow mx-6 lg:mx-14">
							<div className="flex flex-col">
								<div className="font-secondary-regular mb-2 mt-7 clients-heading">
									{messages[language].heading}
								</div>
								<div className="flex flex-row justify-between gap-x-4 items-center">
									<Input
										className="font-primary-regular w-full md:w-6/12 lg:w-3/12 clients-input"
										debounceTime={500}
										iconRight
										theme="secondary"
										title="Enter a search string"
										type="search"
										placeholder={messages[language].search}
										value={props.searchQuery}
										onChange={props.onSearch}
										onEnter={props.onSearch}
									/>
									<Button
										className="font-primary-medium grow-0 min-w-fit px-4 py-2.5 text-lg lg:text-base w-auto whitespace-nowrap clients-button"
										theme="primary"
										onClick={props.onToggleNewClientModal}
									>
										Add client
									</Button>
								</div>
							</div>
							<div>
								<div className="flex flex-col w-full justify-center clients-content-inner">
									{!props.loading && props.clients.length === 0 ? (
										<div className="font-primary-regular my-14 opacity-70 text-base text-center">
											No client data to display
										</div>
									) : props.isDesktop ? (
										<Table
											className="bg-white mb-10 clients-cards-outer"
											headers={getTableHeadersContent()}
											data={getTableDataContent()}
											pagination={
												props.clients.length > 0 && props.pageCount > 0
											}
											currentPage={props.page}
											totalPages={props.pageCount}
											onNavigatePage={props.onNavigatePage}
										/>
									) : (
										<div className="mb-7 clients-cards-outer">
											<div className="font-primary-medium text-lg border-b-2 px-5 py-7 clients-cards-heading">
												{messages[language].subHeading}
											</div>
											<div className="flex flex-col clients-cards-inner">
												{props.clients.map((item) => {
													const client = getFormattedClientSummary(item);

													return (
														<div key={client.lastName + client.lastName}>
															<ClientAccordion
																allowanceAvailable={client.allowanceAvailable}
																clientId={item.id}
																clientName={client.firstName}
																clientSurname={client.lastName}
																fundsAvailable={client.fundsAvailable}
																minReturn={getMinimumReturnContent(
																	client.minimumReturn,
																)}
																statusContent={getStatusPopover(
																	client.tradeStage,
																	client.tradeStatus,
																	true,
																)}
																tooltipContent={renderTooltip(
																	messages[language].balanceFootnote,
																	"z-30",
																)}
																totalProfit={client.totalProfit}
																onViewClient={props.onViewClient}
															/>
														</div>
													);
												})}
											</div>
											{props.pageCount > 0 && (
												<div className="flex flex-row justify-center -mx-2 my-7 clients-paginator">
													<Paginator
														currentPage={props.page}
														showFixedFirstPage
														showFixedLastPage
														totalPages={props.pageCount}
														onNavigatePage={props.onNavigatePage}
													/>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</>
				{props.showReduxErrors && (
					<StatusModal
						open
						message={props.reduxErrors}
						showCloseButton
						status="ERROR"
						onClose={props.onClearErrors}
					/>
				)}
			</>
		);
	},
);

export { ClientsView };
