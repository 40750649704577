import { memo } from "react";
import "./paginator.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import type { ViewProperties } from "./properties";

export const PaginatorView = memo((props: ViewProperties) => {
	const currentBlock = Math.floor(
		(props.currentPage - 1) / props.safeBlockSize,
	);
	const totalBlocks = Math.floor((props.totalPages - 1) / props.safeBlockSize);

	const getLeadingEllipsisContent = () =>
		currentBlock > 0 ? (
			<>
				{props.showFixedFirstPage && props.totalPages > 0 && (
					<div
						key={1}
						className={getPageNumberStyle(1)}
						onClick={() => props.onNavigatePage(1)}
					>
						{1}
					</div>
				)}
				<div
					className="font-primary-bold paginator-clickable"
					onClick={props.onNavigatePreviousBlock}
				>
					...
				</div>
			</>
		) : undefined;

	const getTrailingEllipsisContent = () =>
		currentBlock !== totalBlocks ? (
			<>
				<div
					className="font-primary-bold paginator-clickable"
					onClick={props.onNavigateNextBlock}
				>
					...
				</div>
				{props.showFixedLastPage && props.totalPages > 0 && (
					<div
						key={props.totalPages}
						className={getPageNumberStyle(props.totalPages)}
						onClick={() => props.onNavigatePage(props.totalPages)}
					>
						{props.totalPages}
					</div>
				)}
			</>
		) : undefined;

	const getLeadingPagerContent = () => {
		const pagerEnabled = props.currentPage > 1;

		return (
			<div
				className="my-auto"
				onClick={pagerEnabled ? props.onNavigatePreviousPage : undefined}
			>
				<FaChevronLeft className={getPagerStyle(pagerEnabled)} size="16px" />
			</div>
		);
	};

	const getTrailingPagerContent = () => {
		const pagerEnabled = props.currentPage < props.totalPages;

		return (
			<div
				className="my-auto"
				onClick={pagerEnabled ? props.onNavigateNextPage : undefined}
			>
				<FaChevronRight className={getPagerStyle(pagerEnabled)} size="16px" />
			</div>
		);
	};

	const getPageNumberContent = () => {
		const startPage =
			(Math.floor((props.currentPage - 1) / props.safeBlockSize) + 1) *
				props.safeBlockSize -
			props.safeBlockSize;
		const endPage = Math.min(startPage + props.safeBlockSize, props.totalPages);

		return [...Array(endPage - startPage)].map((item, index) => {
			const pageNumber = index + startPage + 1;
			return (
				<div
					key={pageNumber}
					className={getPageNumberStyle(pageNumber)}
					onClick={() => props.onNavigatePage(pageNumber)}
				>
					{pageNumber}
				</div>
			);
		});
	};

	const getPageNumberStyle = (page: number) => {
		const pageNumberStyle = ["font-primary-bold"];
		if (page === props.currentPage) {
			pageNumberStyle.push("paginator-active");
		}
		pageNumberStyle.push("paginator-clickable");

		return pageNumberStyle.join(" ");
	};

	const getPagerStyle = (enabled: boolean) => {
		const pagerStyle = [
			"flex",
			"items-center",
			"paginator-clickable",
			"w-auto",
			"h-4",
		];
		if (!enabled) {
			pagerStyle.push("paginator-chevron--disabled");
		}
		return pagerStyle.join(" ");
	};

	return props.totalPages > 0 ? (
		<div className="flex flex-wrap justify-evenly lg:gap-x-2.5 lg:justify-center lg:w-auto max-h-full min-w-min text-lg w-3/4">
			{getLeadingPagerContent()}
			{getLeadingEllipsisContent()}
			{getPageNumberContent()}
			{getTrailingEllipsisContent()}
			{getTrailingPagerContent()}
		</div>
	) : (
		<></>
	);
});
