import type { TradeStage } from "@app/constants/trade-stage";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import { TradeStatusCardView } from "./trade-status-card-view";

export const TradeStatusCard = (props: {
	className?: string;
	investedAmount: number;
	isDesktop?: boolean;
	netProfit: number;
	stage: TradeStage;
	status: string;
}) => {
	return (
		<BreakpointWrapper
			desktopView={<TradeStatusCardView {...props} isDesktop />}
			mobileView={<TradeStatusCardView {...props} />}
		/>
	);
};
