import type { ChartType, GraphSet } from "@app/entities";
import type { RootState } from "@app/redux";
import { clampNumber } from "@app/utils/clamp-number";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import type { TooltipItem } from "chart.js";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import type { Properties, ViewProperties } from "./properties";
import { StatisticsCardView } from "./statistics-card-view";

export const StatisticsCard = (props: Properties) => {
	const { activeClient, statisticsLoading } = useSelector(
		(rootState: RootState) => rootState.clients,
	);

	const mapStatistics = (): GraphSet => {
		const statsData = props.statistics?.data ?? undefined;

		return {
			data: {
				data: statsData
					? statsData.map<number>((dataPoint) => dataPoint.y.value)
					: activeClient?.defaultStat
						? activeClient?.defaultStat?.data.map<number>(
								(dataPoint) => dataPoint.y.value,
							)
						: [],
				label: props.statistics
					? props.statistics?.title
					: activeClient?.defaultStat
						? activeClient.defaultStat.title
						: "",
			},
			labels: statsData
				? statsData.map<string>((dataPoint) => dataPoint.x.label)
				: activeClient?.defaultStat
					? activeClient.defaultStat?.data.map((dataPoint) => dataPoint.x.label)
					: [],
		};
	};

	const graphData: GraphSet = useMemo(() => {
		return mapStatistics();
	}, [props.value, props.statistics]);

	const onRenderTooltipLabel = (tooltip: TooltipItem<ChartType>) => {
		return props.statistics
			? `${props.statistics.title}: ${
					props.statistics.data[
						clampNumber(tooltip.dataIndex, 0, props.statistics.data.length - 1)
					].y.label
				}`
			: `${tooltip.dataset.label ?? ""}: ${tooltip.parsed.y}`;
	};

	const viewProps: ViewProperties = {
		...props,
		chartData: graphData?.data,
		chartLabels: graphData?.labels,
		loading: props.loading || statisticsLoading,
		onRenderTooltipLabel: onRenderTooltipLabel,
	};

	return (
		<BreakpointWrapper
			desktopView={<StatisticsCardView {...viewProps} isDesktop />}
			mobileView={<StatisticsCardView {...viewProps} />}
		/>
	);
};
