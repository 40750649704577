import { StatusModal } from "@app/components/modals/status-modal";
import { ViewStatementModal } from "@app/components/modals/view-statement-modal";
import { type Row, Table } from "@app/components/table";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import React from "react";
import { useSelector } from "react-redux";
import messages from "./messages";
import type { ViewProperties } from "./properties";
import "./summary-of-trades-card.css";
import { FiDownload } from "react-icons/fi";
import { IoEye } from "react-icons/io5";

export const SummaryOfTradesCardView = React.memo((props: ViewProperties) => {
	const { language } = useSelector((state: RootState) => state.language);

	const tableCurrencyMediumStyle = [
		"whitespace-nowrap",
		"font-primary-medium",
	].join(" ");

	const getContainerClasses = () => {
		const classesToUse = ["summary-of-trades pt-5 bg-white"];
		if (props.className) classesToUse.push(props.className);
		return classesToUse.join(" ");
	};

	const getViewAllButton = () => {
		return (
			<Button
				className="grow-0 w-auto font-primary-medium text-lg"
				theme={"secondary"}
				onClick={props.onViewAll}
			>
				{messages[language].viewAll}
			</Button>
		);
	};

	const getHeaders = () => {
		return {
			cells: [
				{
					content: messages[language].amount,
				},
				{
					content: messages[language].netReturn,
				},
				{
					content: messages[language].netProfit,
				},
				{
					content: <></>,
				},
			],
		};
	};

	const getRows = () => {
		const currencyOptions: FormatNumberOptions = {
			currencyCode: messages[language].currencyPrefix,
			decimalPlaces: props.mobile ? 0 : 2,
			groupSeparator: messages[language].groupSeparator,
		};

		const percentageOptions: FormatNumberOptions = {
			decimalPlaces: 2,
			groupSeparator: messages[language].groupSeparator,
			isPercentage: true,
		};

		const defaultValue = props.mobile
			? messages[language].defaultValueMobile
			: messages[language].defaultValue;

		return props.data.map<Row>((trade) => {
			const netReturn = +trade.netReturn;

			return {
				cells: [
					{
						className: tableCurrencyMediumStyle,
						content:
							+trade.amount > 0
								? formatNumber(trade.amount, currencyOptions)
								: defaultValue,
					},
					{
						content:
							netReturn > 0
								? formatNumber(netReturn * 100, percentageOptions)
								: messages[language].defaultPercent,
					},
					{
						className: "whitespace-nowrap",
						content:
							+trade.netProfit > 0
								? formatNumber(trade.netProfit, currencyOptions)
								: defaultValue,
					},
					{
						content: (
							<div className="flex justify-end">
								<Button
									className="icon-button shrink-0 grow-0 p-0 mr-7 lg:mr-9 opacity-50"
									onClick={() => props.onViewTradeStatement(trade.id)}
								>
									<IoEye
										className="summary-of-trades icon-filled"
										size="20px"
									/>
								</Button>
								<Button
									className="icon-button shrink-0 grow-0 p-0 mr-1 lg:mr-6 opacity-50"
									onClick={() => props.onDownloadTradeStatement(trade.id)}
								>
									<FiDownload className="summary-of-trades" size="20px" />
								</Button>
							</div>
						),
					},
				],
			};
		});
	};

	const getNoResultsContent = () => (
		<div className="font-primary-regular my-14 opacity-70 text-base text-center">
			{messages[language].noResults}
		</div>
	);

	return (
		<>
			<div className={getContainerClasses()}>
				<div className="header flex justify-between items-center mb-5 gap-x-3 px-5 and lg:px-7">
					<h1 className="font-primary-bold">{messages[language].header}</h1>
					{getViewAllButton()}
				</div>
				{props.data.length === 0 ? (
					getNoResultsContent()
				) : (
					<Table headers={getHeaders()} data={getRows()} />
				)}
			</div>
			{props.viewStatement && (
				<ViewStatementModal
					contentType={props.statementContentType}
					base64Data={props.statementData}
					fileName={props.statementFileName}
					open
					tradeId={props.statementId}
					onClose={props.onCloseTradeStatement}
					onDownload={props.onDownloadTradeStatement}
				/>
			)}
			{props.showErrors && (
				<StatusModal
					open
					message={props.errors}
					status="ERROR"
					showCloseButton
					onClose={props.onClearErrors}
				/>
			)}
		</>
	);
});
