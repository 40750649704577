import { dateFormats } from "@app/constants/date-formats";
import type {
	Client,
	ClientResponse,
	ClientSummariesResponse,
	ClientSummary,
	Cycle,
	CycleListResponse,
	StatementResponse,
	StatisticsResponse,
} from "@app/entities";
import { concatenateArrays } from "@app/utils/concatenate-arrays";
import { convertBlobToBase64 } from "@app/utils/convert-blob-to-base64";
import { isArray } from "lodash";
import moment from "moment";
import { authorizedApi } from "./api";
import type {
	CreateClientFailureResponse,
	CreateClientRequest,
	GenericFailureResponse,
	GenericResponse,
	GetClientResponse,
	GetClientSummariesResponse,
	GetClientSummaryResponse,
	GetCycleListResponse,
	GetCycleResponse,
	GetStatisticsResponse,
	MappedReasons,
	SaveKrakenDetailsFailureResponse,
} from "./models";

export async function getClientSummaries(
	pageSize = 10,
	pageNumber = 1,
	searchQuery?: string,
): Promise<ClientSummariesResponse | GenericResponse> {
	try {
		const { data } = await authorizedApi.get<GetClientSummariesResponse>(
			`client_list/?offset=${
				pageSize * getApiPageNumber(pageNumber)
			}&limit=${pageSize}&search=${searchQuery ?? ""}`,
		);

		return {
			total: data.count,
			totalPages: Math.ceil(data.count / pageSize),
			clients: data.results.map(mapToClientSummary),
		};
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays(error.genericErrors, [
			error.detail ?? "",
		]);

		return {
			reasons: reasons,
		};
	}
}

export async function getClient(
	clientId: number,
): Promise<ClientResponse | GenericResponse> {
	try {
		var { data } = await authorizedApi.get<GetClientResponse>(
			`get_client/${clientId}/`,
		);

		return mapToClient(data);
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function getStatistics(
	clientId: number,
	slug: string,
): Promise<StatisticsResponse | GenericResponse> {
	try {
		var { data } = await authorizedApi.get<GetStatisticsResponse>(
			`get_client/${clientId}/statistics/${slug}/`,
		);

		return mapToStatistics(data);
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function getCycleList(
	clientId: number,
	startDate?: Date,
	endDate?: Date,
	pageSize = 10,
	pageNumber = 1,
	download?: string,
): Promise<CycleListResponse | StatementResponse | GenericResponse> {
	try {
		const startDateFormatted = moment(startDate).format(
			dateFormats.reverseIso8601,
		);

		const startDateFilter = startDate
			? `&start_date=${startDateFormatted}`
			: "";

		const endDateFormatted = moment(endDate).format(dateFormats.reverseIso8601);

		const endDateFilter = endDate ? `&end_date=${endDateFormatted}` : "";

		const downloadFormatted = download?.toLowerCase();

		const downloadFilter = download ? `&download=${downloadFormatted}` : "";

		const query = `cycle_list/${clientId}/?offset=${
			pageSize * getApiPageNumber(pageNumber)
		}&limit=${pageSize}${startDateFilter}${endDateFilter}${downloadFilter}`;

		if (!download) {
			var { data } = await authorizedApi.get<GetCycleListResponse>(query);

			return {
				total: data.count,
				totalPages: Math.ceil(data.count / pageSize),
				cycles: data.results.map(mapToCycle),
			};
		} else {
			var { data: fileData, headers } = await authorizedApi.get(query, {
				responseType: "blob",
			});

			var fileName = "";

			try {
				fileName =
					(headers["Content-Disposition"] || headers["content-disposition"])
						?.split(";")
						.find((x) => /filename/gi.test(x))
						?.trim()
						.split("=")[1] ?? "";

				fileName = fileName.replace(/(^"|"$)/g, "");
			} catch {}

			if (!fileName) {
				fileName = `Cycles - ${
					startDate ? startDateFormatted : "up"
				} to ${endDateFormatted}.${downloadFormatted}`;
			} else {
				fileName = fileName.trim();
			}

			var contentType = headers["content-type"];

			return {
				fileName: fileName,
				contentType: contentType,
				data: await convertBlobToBase64(fileData),
			};
		}
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function getStatement(
	statementId: number,
): Promise<StatementResponse | GenericResponse> {
	try {
		var { data, headers } = await authorizedApi.get(
			`get_statement/${statementId}/`,
			{
				responseType: "blob",
			},
		);

		var fileName = "";

		try {
			fileName =
				(headers["Content-Disposition"] || headers["content-disposition"])
					?.split(";")
					.find((x) => /filename/gi.test(x))
					?.trim()
					.split("=")[1] ?? "";
		} catch {}

		if (!fileName) fileName = `Statement ${statementId}.pdf`;

		var contentType = headers["content-type"];

		return {
			fileName: fileName,
			contentType: contentType,
			data: await convertBlobToBase64(data),
		};
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function createClient(
	client: Client,
): Promise<GenericResponse | undefined> {
	try {
		var request = mapToClientRequest(client);

		await authorizedApi.post(`create_client/`, request);
	} catch (exception: any) {
		var error = exception.data as CreateClientFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			error.contact_number,
			error.first_name,
			error.id_number,
			error.initial_investment,
			error.last_name,
			error.middle_names,
			error.p_address,
			error.p_city,
			error.p_country,
			error.p_other_province,
			error.p_post_code,
			error.p_province,
			error.p_suburb,
			error.r_address,
			error.r_city,
			error.r_country,
			error.r_other_province,
			error.r_post_code,
			error.r_province,
			error.r_suburb,
			error.referred_by,
			error.referred_by_details,
			error.secondary_email,
			error.tax_number,
		);

		var mappedReasons: MappedReasons = {
			firstName: error.first_name,
			middleName: error.middle_names,
			lastName: error.last_name,
			contactNumber: error.contact_number,
			initialInvestment: error.initial_investment,
			idNumber: error.id_number,
			taxNumber: error.tax_number,
			residentialAddress: error.r_address,
			residentialSuburb: error.r_suburb,
			residentialCity: error.r_city,
			residentialCountry: error.r_country,
			residentialPostalCode: error.r_post_code,
			residentialProvince: error.r_province,
			residentialProvinceOther: error.r_other_province,
			postalAddress: error.p_address,
			postalSuburb: error.p_suburb,
			postalCity: error.p_city,
			postalCountry: error.p_country,
			postalPostalCode: error.p_post_code,
			postalProvince: error.p_province,
			postalProvinceOther: error.p_other_province,
			referredBy: error.referred_by,
			referredByDetails: error.referred_by_details,
			secondaryEmail: error.secondary_email,
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function saveKrakenDetails(
	clientId: number,
	krakenId: string,
	krakenApiKey: string,
	krakenApiSecret: string,
): Promise<GenericResponse | string[]> {
	try {
		const { data } = await authorizedApi.put<string>(
			`kraken_details/${clientId}/`,
			{
				kraken_id: krakenId,
				kraken_api_key: krakenApiKey,
				new_kraken_api_secret: krakenApiSecret,
			},
		);

		if (!isArray(data)) return [data];

		return data;
	} catch (exception: any) {
		var error = exception.data as SaveKrakenDetailsFailureResponse;

		if (isArray(error))
			return {
				reasons: error,
			};

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		var mappedReasons: MappedReasons = {
			clientId: error.client_id,

			krakenId: error.kraken_id,
			krakenApiKey: error.kraken_api_key,
			krakenApiSecret: error.new_kraken_api_secret,
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

function mapToStatistics(value: GetStatisticsResponse): StatisticsResponse {
	return {
		chartType: value.chart_type,
		data: value.data,
		slug: value.slug,
		title: value.title,
	};
}

function mapToClientSummary(value: GetClientSummaryResponse): ClientSummary {
	return {
		allowanceAvailable: value.allowance_available && {
			sda: value.allowance_available.sda,
			sdaDetails: value.allowance_available.sda_details && {
				reserved: value.allowance_available.sda_details.reserved,
				sdaUnused: value.allowance_available.sda_details.sda_unused,
				sdaUsed: value.allowance_available.sda_details.sda_used,
			},
			fia: value.allowance_available.fia,
			fiaDetails: value.allowance_available.fia_details,
		},
		firstName: value.first_name,
		fundsAvailable: value.funds_available,
		fundsLastUpdated: value.funds_last_updated && {
			datetime: value.funds_last_updated.datetime,
			string: value.funds_last_updated.string,
			warningMessage: value.funds_last_updated.warning_message,
		},
		id: value.id,
		lastName: value.last_name,
		minimumReturn: value.minimum_return,
		totalProfit: value.total_profit,
		tradeStatus: value.trade_status && {
			id: value.trade_status.id,
			amountInvested: value.trade_status.amount_invested,
			label: value.trade_status.label,
			netProfit: value.trade_status.net_profit,
			position: value.trade_status.position,
			statement: value.trade_status.statement,
		},
		onboardingComplete: value.onboarding_complete,
	};
}

function mapToClient(value: GetClientResponse): ClientResponse {
	return {
		allowanceAvailable: value.allowance_available && {
			sda: value.allowance_available.sda,
			sdaDetails: value.allowance_available.sda_details && {
				reserved: value.allowance_available.sda_details.reserved,
				sdaUnused: value.allowance_available.sda_details.sda_unused,
				sdaUsed: value.allowance_available.sda_details.sda_used,
			},
			fia: value.allowance_available.fia,
			fiaDetails: value.allowance_available.fia_details,
		},
		firstName: value.first_name,
		fundsAvailable: value.funds_available,
		fundsLastUpdated: value.funds_last_updated && {
			datetime: value.funds_last_updated.datetime,
			string: value.funds_last_updated.string,
			warningMessage: value.funds_last_updated.warning_message,
		},
		id: value.id,
		lastName: value.last_name,
		minimumReturn: value.minimum_return,
		totalProfit: value.total_profit,
		tradeStatus: value.trade_status && {
			id: value.trade_status.id,
			amountInvested: value.trade_status.amount_invested,
			label: value.trade_status.label,
			netProfit: value.trade_status.net_profit,
			position: value.trade_status.position,
			statement: value.trade_status.statement,
		},
		relationshipManager: value.rm && {
			contactNumber: value.rm.contact_number,
			email: value.rm.email,
			firstName: value.rm.first_name,
			lastName: value.rm.last_name,
			picture: value.rm.picture,
		},
		defaultStat: value.default_stat && mapToStatistics(value.default_stat),
		depositBank: value.deposit_bank && {
			accountType: value.deposit_bank.account_type,
			bank: value.deposit_bank.bank,
			branchCode: value.deposit_bank.branch_code,
			depositAccountNumber: value.deposit_bank.deposit_account_number,
		},
		onboardingComplete: value.onboarding_complete,
		spouse: value.spouse
			? {
					firstName: value.spouse.first_name,
					id: value.spouse.id,
					lastName: value.spouse.last_name,
				}
			: null,
		krakenApiTestSuccess: value.kraken_api_test_success,
	};
}

function mapToCycle(value: GetCycleResponse): Cycle {
	return {
		clientProfit: value.client_profit,
		clientProfitPercentage: value.client_profit_perc,
		cycleCode: value.cycle_code,
		id: value.id,
		startDate: value.start_date,
		zarIn: value.ZAR_in,
	};
}

function mapToClientRequest(value: Client): CreateClientRequest {
	return {
		contact_number: value.contactNumber,
		first_name: value.firstName,
		id_number: value.idNumber,
		initial_investment: value.initialInvestment,
		last_name: value.lastName,
		middle_names: value.middleName,
		p_address:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalAddress,
		p_city:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalCity,
		p_country:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalCountry,
		p_other_province:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalProvinceOther,
		p_post_code:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalPostalCode,
		p_province:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalProvince,
		p_suburb:
			value.isPostalSameAsResidentialAddress === "yes"
				? null
				: value.postalSuburb,
		r_address: value.residentialAddress,
		r_city: value.residentialCity,
		r_country: value.residentialCountry,
		r_other_province: value.residentialProvinceOther,
		r_post_code: value.residentialPostalCode,
		r_province: value.residentialProvince,
		r_suburb: value.residentialSuburb,
		referred_by: value.referredBy,
		referred_by_details: value.referredByDetails,
		secondary_email: value.secondaryEmail,
		tax_number: value.taxNumber,
	};
}

function getApiPageNumber(pageNumber: number) {
	return Math.max(0, pageNumber - 1);
}
