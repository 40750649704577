import hamburgerImage from "@app/assets/images/hamburger-menu.svg";
import navLogo from "@app/assets/images/nav-logo.png";
import noProfileImage from "@app/assets/images/no-profile-image.svg";
import { LogoutModal } from "@app/components/modals/logout-modal";
import { NewClientModal } from "@app/components/modals/new-client-modal";
import { Notifications } from "@app/components/notifications";
import { Sidebar } from "@app/components/sidebar";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import { Button } from "@app/controls/button";
import { Dropdown } from "@app/controls/dropdown";
import type { RootState } from "@app/redux";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import { BsQuestionCircle } from "react-icons/bs";
import type { Properties } from "./properties";

const messages = {
	en: {
		add: "(add)",
		client: "Client:",
		change: "(change)",
		default: {
			clientName: "...",
			contactNumber: "...",
			email: "...",
			emailAddress: "...",
			rmFirstName: "...",
			rmLastName: "",
		},
		relationshipManager: "Your Relationship Manager",
		accountSettings: "Account Settings",
		addClient: "Add Client",
		viewClients: "View Clients",
		logout: "Logout",
	},
};

export const NavbarView = React.memo((props: Properties) => {
	const { language } = useSelector((state: RootState) => state.language);
	const { clients } = useSelector((state: RootState) => state.clients);
	const { profileLoading } = useSelector((state: RootState) => state.auth);

	const { pathname } = useLocation();

	const hideNavbarDetails =
		pathname === paths.clients || pathname === paths.account;

	const resizeObserver = React.useMemo(
		() =>
			new ResizeObserver((entries) => {
				entries.forEach((entry) =>
					document.documentElement.style.setProperty(
						"--navbar-height",
						`${entry.target.clientHeight}px`,
					),
				);
			}),
		[],
	);

	useEffect(() => {
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const getClientSection = () => {
		return (
			<>
				<div className="client-details flex items-center">
					<Link
						to={pathname === paths.clients ? paths.clients : paths.dashboard}
					>
						<img className="shrink-0 mr-2" src={navLogo} alt="nav-logo" />
					</Link>
					{!hideNavbarDetails ? (
						<>
							<div className="details mr-1 md:mr-2 lg:mr-auto min-w-0">
								{!props.mobile && <div>{messages[language].client}</div>}
								<span className="client-name font-primary-bold">
									{props.clientName}
								</span>
								<a
									className="cursor-pointer"
									onClick={() =>
										props.showAddClient
											? props.onToggleNewClientModal()
											: props.onNavigate(paths.clients)
									}
								>
									{props.showAddClient
										? messages[language].add
										: messages[language].change}
								</a>
							</div>
							<div className="nav-sep" />
						</>
					) : undefined}
				</div>
			</>
		);
	};

	const getManagerSection = () => {
		return (
			<div className="relationship-manager flex flex-wrap flex-shrink-0 items-center gap-y-2">
				<div className="your-relationship-manager">
					{messages[language].relationshipManager}
				</div>
				<img
					src={props.manager.picture || noProfileImage}
					alt="manager-image"
				/>
				<div className="manager-details flex mr-2 font-normal">
					<span className="manager-name">{`${props.manager.firstName} ${props.manager.lastName}`}</span>
					<span className="text-sep">|</span>
					<a
						href={`mailto:${props.manager.email}`}
						target="_blank"
						className="manager-email"
						rel="noreferrer"
					>
						{props.manager.email}
					</a>
					<span className="text-sep">|</span>
					<span className="manager-contact">{props.manager.contactNumber}</span>
				</div>
			</div>
		);
	};

	const getAccountSection = () => {
		const showAddClient = clients.length <= 1;

		return (
			<div className="ml-auto account flex">
				<Notifications className="notifications mr-9" />
				<a
					className="flex flex-col justify-center question mr-7"
					href={links.contact}
					target="_blank"
					rel="noreferrer"
				>
					<BsQuestionCircle size="28px" />
				</a>
				<Dropdown
					theme="navbar"
					header={props.userEmail}
					items={[
						{
							label: showAddClient
								? messages[language].addClient
								: messages[language].viewClients,
							value: showAddClient
								? messages[language].addClient
								: paths.clients,
						},
						{
							label: messages[language].accountSettings,
							value: paths.account,
						},
						{
							label: "Visit website",
							value: links.home,
						},
						{
							label: messages[language].logout,
							value: paths.logout,
						},
					]}
					fixedHeader
					onSelect={props.onNavigate}
				/>
			</div>
		);
	};

	const getHamburgerMenu = () => {
		return !props.showSidebar ? (
			<Button
				className="ml-auto burger-menu flex-grow-0"
				onClick={props.onToggleSidebar}
			>
				<img src={hamburgerImage} alt="burger-menu" />
			</Button>
		) : undefined;
	};

	return (
		<>
			<nav
				ref={(element) => {
					if (!props.mobile && element) {
						resizeObserver.disconnect();
						resizeObserver.observe(element);
					}
				}}
				className="top-navbar flex items-center sticky top-0 z-50"
			>
				{getClientSection()}
				<div className="main-section flex flex-1 items-center font-primary-medium">
					{!hideNavbarDetails && getManagerSection()}
					{props.mobile ? getHamburgerMenu() : getAccountSection()}
				</div>
				{props.mobile && (
					<Sidebar open={props.showSidebar} onClose={props.onToggleSidebar} />
				)}
				{props.showLogoutModal && (
					<LogoutModal open onClose={props.onCloseLogoutModal} />
				)}
				{props.showNewClientModal && !profileLoading && (
					<NewClientModal open onClose={() => props.onToggleNewClientModal()} />
				)}
			</nav>
		</>
	);
});
