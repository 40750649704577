import background from "@app/assets/images/background.jpg";
import logo from "@app/assets/images/logo-home.svg";
import { CustomLoader } from "@app/components/custom-loader";
import { ActivateAccountModal } from "@app/components/modals/activate-account-modal";
import { ChangePasswordModal } from "@app/components/modals/change-password-modal";
import { StatusModal } from "@app/components/modals/status-modal";
import { links } from "@app/constants/links";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import { memo } from "react";
import "./login.css";

type FormKey = "username" | "password";

export type FormValues = {
	[key in FormKey]?: string;
};

export const LoginView = memo(
	(props: {
		activationStatus?: "success" | "error" | "stale";
		errors?: string[];
		loading?: boolean;
		openModal: boolean;
		openResetModal: boolean;
		paramToken?: string;
		paramUser?: string;
		resetError?: string;
		resetStatus?: boolean;
		showResendSuccessModal?: boolean;
		isResendingEmail: boolean;
		validate: boolean;
		onClearError: () => void;
		onClose: () => void;
		onCloseResendSuccess?: () => void;
		onCloseReset: (passwordResetSuccessfully?: boolean) => void;
		onForgotPassword: () => void;
		onLogin: (values: FormValues) => void;
		onRegister: () => void;
		onResend: () => void;
		onResetStatus: () => void;
		onValidate: (errors: FormValues, submit: () => void) => void;
	}) => {
		return (
			<>
				<div className="flex flex-col lg:flex-row lg:h-screen">
					<div className="flex flex-col grow lg:overflow-y-auto w-full h-full">
						<a
							className="my-2 p-4 group w-fit"
							href={links.home}
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="group-hover:sm:ml-1 object-contain w-fit login-home"
								src={logo}
								alt="Home"
							/>
						</a>
						<div className="flex flex-col my-auto mx-auto px-6 sm:px-0 w-full sm:w-4/5 md:w-9/12 lg:w-3/5 login-form-body">
							<Formik
								initialValues={{ password: "", username: "" }}
								onSubmit={props.onLogin}
							>
								{({ handleSubmit, validateForm }) => (
									<>
										<div className="font-secondary-regular py-0 login-heading">
											Login
										</div>
										<div className="my-6 rounded-full w-24 login-heading-separator" />
										<Transition
											show={
												props.errors !== undefined && props.errors.length > 0
											}
											enter="transition ease-out duration-300"
											leave="transition east-out duration-300"
											enterFrom="opacity-0 translate-y-5"
											enterTo="opacity-100 translate-y-0"
											leaveTo="opacity-0 -translate-y-5"
										>
											<div className="flex flex-col gap-y-2">
												<div
													className="font-primary-regular login-error"
													dangerouslySetInnerHTML={{
														__html: `<p>${props.errors?.join("</p><p>")}</p>`,
													}}
												/>
											</div>
										</Transition>
										<div className="flex flex-col gap-y-5 pt-3 w-full">
											<Input
												className="pl-5 py-2.5 login-input"
												iconClass="login-icon"
												iconName="FiUser"
												name="username"
												required
												peekablePassword
												placeholder="Email address"
												title="Please enter your email address"
												type="text"
												useFormik
												validateOnChange={props.validate}
												onChange={props.onClearError}
											/>
											<Input
												className="pl-5 py-2.5 login-input"
												iconClass="login-icon"
												name="password"
												required
												peekablePassword
												placeholder="Password"
												title="Please enter your password"
												type="password"
												useFormik
												validateOnChange={props.validate}
												onChange={props.onClearError}
												onEnter={async () =>
													props.onValidate(await validateForm(), handleSubmit)
												}
											/>
											<Button
												className="font-primary-bold mt-4 py-5 text-lg uppercase"
												theme="primary"
												onClick={async () =>
													props.onValidate(await validateForm(), handleSubmit)
												}
											>
												SUBMIT
											</Button>
											<Button
												className="opacity-60 font-primary-regular mt-4 py-0 self-center lg:self-start text-left text-lg login-forgot"
												onClick={props.onForgotPassword}
											>
												Forgot / Reset password
											</Button>
										</div>
									</>
								)}
							</Formik>
						</div>
					</div>
					<div className="flex flex-col grow lg:overflow-y-auto w-full  lg:h-full">
						<div
							className="bg-cover bg-center flex flex-col justify-center items-center h-full px-6 py-24 lg:py-0 login-background"
							style={{ backgroundImage: `url(${background})` }}
						>
							<div className="font-secondary-regular -mb-1 text-3xl text-center login-register-text">
								Don't have an account?
							</div>
							<div className="my-6 rounded-full w-24 login-heading-separator" />
							<Button
								className="font-primary-bold grow-0 min-w-fit py-5 px-5 text-lg uppercase w-4/12"
								theme="primary"
								onClick={props.onRegister}
							>
								REGISTER
							</Button>
						</div>
					</div>
				</div>

				{props.openModal && (
					<ActivateAccountModal
						isResendingEmail={props.isResendingEmail}
						open
						onClose={props.onClose}
						onResend={props.onResend}
					/>
				)}
				{props.openResetModal && (
					<ChangePasswordModal
						open
						paramToken={props.paramToken}
						paramUser={props.paramUser}
						resetPassword
						onClose={props.onCloseReset}
					/>
				)}
				{props.activationStatus !== undefined && (
					<StatusModal
						heading={
							props.activationStatus === "success"
								? "Account successfully activated"
								: props.activationStatus === "stale"
									? "Your account is already activated"
									: "The activation link has expired"
						}
						headingClassName="mb-0"
						message={
							props.activationStatus === "success" ? (
								"You can now login!"
							) : props.activationStatus === "stale" ? (
								"You can proceed to login now"
							) : (
								<p>
									Activation links expire after 7 days.
									<br />
									To resend, log in to your account.
								</p>
							)
						}
						messageClassName="mt-0"
						open={props.activationStatus !== undefined}
						status={props.activationStatus === "error" ? "ERROR" : "SUCCESS"}
						showCloseButton
						onClose={props.onResetStatus}
					/>
				)}
				{props.resetStatus !== undefined && (
					<StatusModal
						className="login-success"
						heading={
							props.resetStatus === true
								? "Successfully reset password"
								: "The password could not be reset"
						}
						headingClassName="mb-0"
						message={
							props.resetStatus === true
								? "You can now login!"
								: props.resetError
						}
						messageClassName="mt-0"
						open={props.resetStatus !== undefined}
						status={props.resetStatus === true ? "SUCCESS" : "ERROR"}
						showCloseButton
						onClose={props.onResetStatus}
					/>
				)}
				{props.showResendSuccessModal === true && (
					<StatusModal
						buttonContent="OK"
						className="login-success"
						headingClassName="mb-0"
						heading="Activation mail sent"
						message="Please check your inbox"
						messageClassName="mt-0"
						open
						showCloseButton
						status="SUCCESS"
						onClose={props.onCloseResendSuccess}
					/>
				)}
				{props.loading && <CustomLoader page />}
			</>
		);
	},
);
