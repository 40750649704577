import { apiErrors } from "@app/constants/api-errors";
import { paths } from "@app/constants/paths";
import { useLogout } from "@app/helpers";
import type { RootState } from "@app/redux";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavbarView } from "./navbar-view";
import type { Properties } from "./properties";

interface State {
	showLogoutModal: boolean;
	showNewClientModal: boolean;
	showNotifications: boolean;
	showSidebar: boolean;
}

const messages = {
	en: {
		add: "(add)",
		client: "Client:",
		change: "(change)",
		default: {
			clientName: "...",
			contactNumber: "...",
			email: "...",
			emailAddress: "...",
			rmFirstName: "...",
			rmLastName: "",
		},
		relationshipManager: "Your Relationship Manager",
		accountSettings: "Account Settings",
		addClient: "Add Client",
		viewClients: "View Clients",
		logout: "Logout",
	},
};

export const Navbar = () => {
	const { activeClient, clients } = useSelector(
		(state: RootState) => state.clients,
	);

	const { profile } = useSelector((state: RootState) => state.auth);

	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const navigate = useNavigate();
	const [logout] = useLogout();

	const [state, setState] = React.useState<State>({
		showLogoutModal: false,
		showNewClientModal: clients.length < 1,
		showNotifications: false,
		showSidebar: false,
	});

	const getProperties = () => {
		const props: Properties = {
			clientName: messages[language].default.clientName,
			manager: {
				firstName: messages[language].default.rmFirstName,
				lastName: messages[language].default.rmLastName,
				email: messages[language].default.email,
				contactNumber: messages[language].default.contactNumber,
				picture: null,
			},
			userEmail: profile?.email || messages[language].default.emailAddress,
			showAddClient: clients.length < 2,
			showLogoutModal: state.showLogoutModal,
			showNewClientModal: state.showNewClientModal,
			showNotifications: state.showNotifications,
			showSidebar: state.showSidebar,
			onCloseLogoutModal: onCloseLogoutModal,
			onNavigate: onNavigate,
			onToggleNewClientModal: onToggleNewClientModal,
			onToggleNotifications: onToggleNotifications,
			onToggleSidebar: onToggleSidebar,
		};

		if (activeClient) {
			props.clientName = `${activeClient.firstName} ${activeClient.lastName}`;

			if (activeClient.relationshipManager) {
				props.manager = {
					firstName: activeClient.relationshipManager.firstName,
					lastName: activeClient.relationshipManager.lastName,
					email: activeClient.relationshipManager.email,
					contactNumber: activeClient.relationshipManager.contactNumber,
					picture: activeClient.relationshipManager.picture,
				};
			}
		}

		return props;
	};

	const onNavigate = (path: string, external?: boolean) => {
		if (path === messages[language].addClient) {
			setState({ ...state, showNewClientModal: true });
			return;
		}
		if (path === paths.logout) {
			logout((errors?: string[]) => {
				const showLogout = !(
					errors &&
					(errors.includes(apiErrors.tokenExpired) ||
						errors.includes(apiErrors.invalidToken))
				);
				setState({ ...state, showLogoutModal: showLogout });
			});
			return;
		}

		if (external || path.startsWith("http")) {
			window.open(path, "_blank", "noreferrer");
		} else {
			navigate(path);
		}
	};

	const onCloseLogoutModal = () => {
		setState({ ...state, showLogoutModal: false });
	};

	const onToggleNewClientModal = () => {
		setState({ ...state, showNewClientModal: !state.showNewClientModal });
	};

	const onToggleNotifications = () => {
		setState({ ...state, showSidebar: !state.showNotifications });
	};

	const onToggleSidebar = () => {
		setState({ ...state, showSidebar: !state.showSidebar });
	};

	const props = getProperties();

	return (
		<BreakpointWrapper
			desktopView={<NavbarView {...props} />}
			mobileView={<NavbarView {...props} mobile />}
		/>
	);
};
