import { useWindowSize } from "@app/utils/use-window-size";

export const BreakpointWrapper = ({
	desktopView,
	mobileView,
	mobileBreakpoint = 1024,
}: {
	desktopView: JSX.Element;
	mobileView: JSX.Element;
	mobileBreakpoint?: number;
}) => {
	const { width } = useWindowSize();

	var showMobile = false;

	if (width < mobileBreakpoint) showMobile = true;

	return showMobile ? mobileView : desktopView;
};
