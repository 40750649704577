import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/belleza";
import "./index.css";
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://e3e039f375b45a01bef16e1a71039481@o4504921484623872.ingest.us.sentry.io/4507329073446912",
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

if (APP_VERSION) {
	console.log(`Version: ${APP_VERSION}`);
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root"),
);
