import { Graphing } from "@app/components/graphing";
import { Dropdown } from "@app/controls/dropdown";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import messages from "./messages";
import type { ViewProperties } from "./properties";
import "./statistics-card.css";

const StatisticsCardView = React.memo((props: ViewProperties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const chartMin = React.useMemo(() => {
		return props.chartData
			? Math.max(0, Math.min(...props.chartData?.data) - 1)
			: 0;
	}, [props.chartData]);

	const dropdownStyle = ["mb-4", "lg:mb-0"].join(" ");

	const colStyle = ["flex", "flex-col", "gap-y-4"].join(" ");

	const figureStyle = [
		"font-primary-light",
		"text-3xl",
		"statistics-card-figure",
	].join(" ");

	const headingStyle = ["font-primary-bold", "statistics-card-heading"].join(
		" ",
	);

	const mainStyle = [
		"flex",
		"flex-col",
		"grow",
		"px-5",
		"lg:px-7",
		"pb-4",
		"pt-6",
		"lg:pb-7",
		"relative",
		"statistics-card",
		props.className,
	].join(" ");

	const rowStyle = [
		"flex",
		"flex-row justify-between",
		"gap-x-4",
		"gap-y-4",
		"flex-wrap",
	].join(" ");

	const graphStyle = ["flex", "w-full", "h-full", "statistics-card-graph"].join(
		" ",
	);

	const getGraphContent = () => (
		<Graphing
			autoSkip={false}
			className={graphStyle}
			datasets={props.chartData ? [props.chartData] : []}
			loading={props.loading}
			labels={props.chartLabels ?? []}
			maxTicksLimit={props.chartData?.data.length ?? undefined}
			type={props.chartType}
			min={chartMin}
			yAxisExaggeration={0.9}
			onRenderTooltipLabel={props.onRenderTooltipLabel}
		/>
	);

	const getHeaderContent = () => (
		<div className={colStyle}>
			<div className={rowStyle}>
				<div className={headingStyle}>{messages[language].heading}</div>
				<Dropdown
					className={dropdownStyle}
					items={props.items}
					selectedValue={props.defaultSelection}
					theme="primary"
					onSelect={props.onChange}
				/>
			</div>
			<div className={figureStyle}>{props.value}</div>
		</div>
	);

	return (
		<div className={mainStyle}>
			{getHeaderContent()}
			{getGraphContent()}
		</div>
	);
});

export { StatisticsCardView };
