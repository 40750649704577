import React from "react";
import type { ViewStatementModalState } from "./models/view-statement-modal-state";
import type { Properties } from "./properties";
import { ViewStatementModalView } from "./view-statement-modal-view";

const ViewStatementModal = (props: Properties) => {
	const defaultState: ViewStatementModalState = {
		pageCount: 0,
		pageSelected: 0,
	};
	const [state, setState] =
		React.useState<ViewStatementModalState>(defaultState);

	const fileData = React.useMemo(() => {
		return props.base64Data !== undefined
			? props.base64Data.indexOf(",") > -1
				? props.base64Data
				: `data:${props.contentType};base64,${props.base64Data}`
			: "";
	}, []);

	const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

	const onChangePage = (page: number) => {
		setState({ ...state, pageSelected: page });
	};

	const onResizeWindow = () => {
		setWindowWidth(window.innerWidth);
	};

	const onSetPageCount = (pageCount: number) => {
		setState({
			...state,
			pageCount: pageCount,
			pageSelected: pageCount > 0 ? 1 : 0,
		});
	};

	React.useEffect(() => {
		window.removeEventListener("resize", onResizeWindow);
		window.addEventListener("resize", onResizeWindow);
		return () => {
			window.removeEventListener("resize", onResizeWindow);
		};
	}, []);

	return (
		<ViewStatementModalView
			{...props}
			fileData={fileData}
			onChangePage={onChangePage}
			onSetPageCount={onSetPageCount}
			pageCount={state.pageCount}
			pageSelected={state.pageSelected}
			windowWidth={windowWidth}
		/>
	);
};

export { ViewStatementModal };
