import { paths } from "@app/constants/paths";
import type { ClientSummariesResponse } from "@app/entities";
import { useGetClient, useGetClientSummaries } from "@app/helpers";
import type { RootState } from "@app/redux";
import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClientsView } from "./clients-view";

interface ClientsState {
	currentPage: number;
	searchQuery?: string;
	showNewClientModal: boolean;
	showReduxErrors: boolean;
}

const Clients = () => {
	const navigate = useNavigate();
	const [getClientSummaries] = useGetClientSummaries();
	const [getClient] = useGetClient();

	const { clients, clientsError, clientsLoading, clientsTotalPages } =
		useSelector((state: RootState) => state.clients);

	const defaultState: ClientsState = {
		currentPage: 1,
		showNewClientModal: false,
		showReduxErrors: false,
	};

	const [state, setState] = React.useState<ClientsState>(defaultState);

	const getReduxErrors = () => {
		return clientsError?.join(" ");
	};

	const onClearErrors = () => {
		setState({ ...state, showReduxErrors: false });
	};

	const onToggleNewClientModal = () => {
		setState({ ...state, showNewClientModal: !state.showNewClientModal });
	};

	const onSearch = (value: string) => {
		setState({ ...state, searchQuery: value });
	};

	const onNavigatePage = (page: number, search?: string) => {
		if (page !== state.currentPage || page === 0) {
			getClientSummaries(
				page,
				search ?? state.searchQuery,
				(response?: ClientSummariesResponse | string[] | undefined) => {
					if (!response || Array.isArray(response)) {
						setState({ ...state, showReduxErrors: true });
					}
				},
			);
			setState({ ...state, currentPage: page });
		}
	};

	const onViewClient = (identifier: number) => {
		getClient(identifier, (response) => {
			if (response && !Array.isArray(response)) {
				navigate(paths.dashboard);
			} else {
				setState({ ...state, showReduxErrors: true });
			}
		});
	};

	useEffect(() => {
		getClientSummaries();
	}, []);

	useEffect(() => {
		if (state.searchQuery !== undefined) {
			onNavigatePage(0, state.searchQuery);
		}
	}, [state.searchQuery]);

	const viewProps = {
		clients: clients,
		loading: clientsLoading,
		page: state.currentPage,
		pageCount: clientsTotalPages,
		reduxErrors: getReduxErrors(),
		showNewClientModal: state.showNewClientModal,
		showReduxErrors: state.showReduxErrors,
		onClearErrors: onClearErrors,
		onToggleNewClientModal: onToggleNewClientModal,
		onNavigatePage,
		onSearch,
		onViewClient,
	};

	return (
		<BreakpointWrapper
			desktopView={<ClientsView {...viewProps} isDesktop />}
			mobileView={<ClientsView {...viewProps} />}
		/>
	);
};

export default Clients;
