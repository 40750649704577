import { BreakpointWrapper } from "@app/wrappers/breakpoint-wrapper";
import React from "react";
import { MinimumReturnCardView } from "./minimum-return-card-view";
import type { MinimumReturnState } from "./models/minimum-return-state";
import type { Properties, ViewProperties } from "./properties";

export const MinimumReturnCard = (props: Properties) => {
	const defaultState: MinimumReturnState = {
		percent: `${props.percent || 0}`,
	};

	const [state, setState] = React.useState<MinimumReturnState>(defaultState);

	const getMinimumReturn = () => {
		return (props.investedAmount ?? 0) * (+state.percent * 0.01);
	};

	const onChangePercent = (percent: string) => {
		setState({ ...state, percent: percent });

		if (props.onChange) props.onChange(percent);
	};

	React.useEffect(() => {
		if (props.percent && props.percent !== state.percent) {
			setState({ ...state, percent: props.percent });
		}
	}, [props.percent]);

	const viewProps: ViewProperties = {
		...props,
		minimumReturn: getMinimumReturn(),
		percent: state.percent,
		onChange: onChangePercent,
	};

	return (
		<BreakpointWrapper
			desktopView={<MinimumReturnCardView {...viewProps} isDesktop />}
			mobileView={<MinimumReturnCardView {...viewProps} />}
		/>
	);
};
