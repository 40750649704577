const messages = {
	en: {
		currencyCode: "R ",
		defaultValue: "R 0",
		defaultValueMobile: "R 0",
		groupSeparator: ",",
		heading: "Statistics",
	},
};

export default messages;
