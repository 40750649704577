const messages = {
	en: {
		amount: "Amount",
		currencyPrefix: "R ",
		defaultPercent: "0%",
		defaultValue: "R 0",
		defaultValueMobile: "R 0",
		groupSeparator: ",",
		header: "Trade History",
		netProfit: "Net Profit",
		netReturn: "Net Return",
		noResults: "No trade data to display",
		viewAll: "View all",
	},
};

export default messages;
