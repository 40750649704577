import React from "react";
import { GraphingView } from "./graphing-view";
import type { GraphingState } from "./models/graphing-state";
import type { Properties, xAxisCallbackFunction } from "./properties";

const Graphing = (props: Properties) => {
	const defaultState: GraphingState = {
		labels: [],
		datasets: [],
	};
	const [state, setState] = React.useState<GraphingState>(defaultState);

	const xAxisCallback: xAxisCallbackFunction = (tickValue, index) => {
		return props.labels[index];
	};

	React.useEffect(() => {
		setState({
			labels: props.labels,
			datasets: props.datasets,
		});
	}, [props.datasets, props.labels]);

	var viewProperties: Properties = {
		...props,
		datasets: state.datasets,
		labels: state.labels,
		xAxisCallback: props.xAxisCallback ?? xAxisCallback,
	};

	return <GraphingView {...viewProperties} />;
};

Graphing.defaultProps = {
	type: "Line",
};

export { Graphing };
