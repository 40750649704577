import { Input } from "@app/controls/input";
import type { RootState } from "@app/redux";
import { formatNumber } from "@app/utils/format-number";
import React from "react";
import { useSelector } from "react-redux";
import { Popover } from "../popovers/popover/popover";
import messages from "./messages";
import "./minimum-return-card.css";
import type { ViewProperties } from "./properties";

const MinimumReturnCardView = React.memo((props: ViewProperties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const getPercentageWidth = () => {
		// Avoid the decimal dot or comma from taking up a full width ch slot
		const percent = props.percent.toString();
		return (
			(percent.includes(".") || percent.includes(",")
				? percent.length - 0.5
				: percent.length) + 0.25
		);
	};

	const getEditingContent = () => (
		<div
			style={{
				// Use currently set font to maintain width of input control to match its text content, implicit with 'ch' unit
				width: `${getPercentageWidth()}ch`,
			}}
		>
			<Input
				className="-mt-0.5"
				hideIcon
				overrideShowValidation={true}
				readOnly
				theme="none"
				title=""
				type="number"
				value={`${props.percent}`}
				onChange={props.onChange}
			/>
		</div>
	);

	const getPopoverContent = () => {
		return (
			<Popover
				toggleContent={
					<div className="font-primary-medium text-sm minimum-return-popover">
						{messages[language].blankText}
					</div>
				}
				popperContent={
					<div
						className="font-primary-regular leading-5 -m-2.5 p-2.5 z-100 minimum-return-footnote"
						dangerouslySetInnerHTML={{
							__html: messages[language].footnote,
						}}
					/>
				}
				showArrow={true}
				showOnHover={props.isDesktop ? true : undefined}
				placement={props.isDesktop ? "right-start" : "bottom"}
				offset={props.isDesktop ? [0, 16] : [0, 16]}
			/>
		);
	};

	const getFiguresContent = () => (
		<div className="flex flex-row">
			<div className="flex flex-row flex-wrap gap-x-10 gap-y-2 lg:gap-x-3.5 items-end leading-none minimum-return-values">
				<div className="font-primary-light leading-none -mb-1 lg:text-3xl minimum-return-figure">
					{props.minimumReturn > 0
						? formatNumber(props.minimumReturn, {
								currencyCode: messages[language].currencyCode,
								decimalPlaces: props.isDesktop ? 2 : 0,
								groupSeparator: messages[language].groupSeparator,
							})
						: messages[language].defaultValue}
				</div>
				{props.minimumReturn > 0 ? (
					<div className="flex flex-row font-primary-light leading-none minimum-return-percent">
						({getEditingContent()}%)
					</div>
				) : (
					<div>{getPopoverContent()}</div>
				)}
				<div className="font-primary-medium text-sm minimum-return-cycle">
					{messages[language].perCycle}
				</div>
			</div>
			<div className="grow" />
		</div>
	);

	const mainStyle = [
		"py-7.5",
		"px-5",
		"lg:px-7",
		"minimum-return",
		"relative z-20",
		props.className,
	].join(" ");

	return (
		<div className={mainStyle}>
			<div className="font-primary-bold minimum-return-heading">
				{messages[language].minimumReturn}
			</div>
			{getFiguresContent()}
		</div>
	);
});

export { MinimumReturnCardView };
