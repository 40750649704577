import { CustomLoader } from "@app/components/custom-loader";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import type {
	ClientSummariesResponse,
	GetClientSummariesHelperResponse,
} from "@app/entities";
import {
	useGetClient,
	useGetClientSummaries,
	useGetProfile,
	useListPeriodSettings,
	useSetPowerLogin,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import { setToken } from "@app/services";
import { isArray } from "lodash";
import React, { useEffect, useState, type ReactNode } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const Auth = ({ children }: { children: ReactNode }) => {
	const [getProfile] = useGetProfile();
	const [setPowerLogin] = useSetPowerLogin();
	const [listPeriodSettings] = useListPeriodSettings();
	const [getClientSummaries] = useGetClientSummaries();
	const [getClient] = useGetClient();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [loadingPowerAuthDetails, setLoadingPowerAuthDetails] =
		useState<boolean>(false);

	const authToken = searchParams.get("auth-token");
	const loginSource = searchParams.get("login-source");

	const { user, userIsAuthenticated, profile } = useSelector(
		(state: RootState) => state.auth,
	);

	if (authToken) {
		setToken(authToken || "");
	} else if (user) {
		setToken(user.token || "");
	}

	useEffect(() => {
		if (loginSource) {
			window.localStorage.setItem("login-source", loginSource);
		}
	}, [loginSource]);

	useEffect(() => {
		if (authToken) {
			const loadingPowerAuthDetailsPromise: Promise<
				any | GetClientSummariesHelperResponse | undefined
			>[] = [];

			setLoadingPowerAuthDetails(true);

			setPowerLogin(authToken);

			loadingPowerAuthDetailsPromise.push(listPeriodSettings());
			loadingPowerAuthDetailsPromise.push(getProfile());
			loadingPowerAuthDetailsPromise.push(getClientSummaries());

			Promise.allSettled(loadingPowerAuthDetailsPromise).then((responses) => {
				let redirectToClientsScreen = false;

				searchParams.delete("auth-token");

				if ("value" in responses[2] && responses[2].value) {
					if (!isArray(responses[2].value)) {
						const clients = responses[2].value as ClientSummariesResponse;

						if (clients.clients.length > 1) {
							redirectToClientsScreen = true;
						} else if (clients.clients.length === 1) {
							getClient(clients.clients[0].id, () => {
								setLoadingPowerAuthDetails(false);
							});
						}
					}
				}

				if (redirectToClientsScreen) {
					setLoadingPowerAuthDetails(false);
					navigate(paths.clients);
				}
			});
		} else if (!userIsAuthenticated) {
			const loginSource = window.localStorage.getItem("login-source");
			const isInactive = searchParams.get("inactive") === "true";

			if (isInactive) {
				navigate(paths.login(isInactive));
				return;
			}

			if (loginSource === "OFP") {
				window.localStorage.removeItem("login-source");
				window.location.href = links.forexLogin();
				return;
			}

			navigate(paths.login());
		} else if (!profile) getProfile();
		return () => {
			setLoadingPowerAuthDetails(false);
		};
	}, [userIsAuthenticated]);

	return (
		<>{user && !loadingPowerAuthDetails ? children : <CustomLoader page />}</>
	);
};

export { Auth };
