import { links } from "@app/constants/links";

const messages = {
	en: {
		city: "City *",
		contactNumber: "Phone Number *",
		country: "Country *",
		email: "Email",
		errors: {
			fieldsRequired: "Please complete the required fields highlighted in red",
			generic: "There are errors in your submission. Please see above.",
		},
		firstName: "First Name *",
		header: "Add Client",
		headerInitial: "Add Details",
		heardAboutUs: "How did you hear about us? *",
		idNumber: "ID Number *",
		initialInvestment: "Initial Investment *",
		lastName: "Last Name *",
		middleName: "Middle Name",
		otherProvince: "Other State/Province *",
		postalAddressHeader: "Postal Address",
		postalCode: "Zip / Postal Code *",
		postalResidentialAddress:
			"Is your postal and residential address the same? *",
		province: "State/Province *",
		referredByDetails: "Referred by *",
		residentialAddressHeader: "Residential Address",
		secondaryEmail: "Secondary email (optional)",
		sourceOfFundsHeader: "Source of Funds *",
		sourceOfFundsLabel: `The funds invested are my own or donated by my spouse and were <a href=${links.sourceOfFundsFaq} target="_blank">not acquired through an informal loan</a>`,
		streetAddress: "Street Address *",
		submit: "SUBMIT",
		suburb: "Suburb *",
		taxNumber: "Tax Number *",
		termsAndConditions: `I agree to the <a href=${links.legal.termsAndConditions} target="_blank">Terms and Conditions</a> and <a href=${links.legal.privacyPolicy} target="_blank">Privacy Policy</a>`,
		tooltip: {
			contactNumber: "Please enter client's contact number",
			firstName: "Please enter client's first name",
			idNumber: "Please enter client's valid ID number",
			initialInvestment: "Please enter client's initial investment",
			lastName: "Please enter client's last name",
			middleName: "Please enter client's middle name",
			postal: {
				address: "Please enter client's postal physical address",
				city: "Please enter client's postal city",
				country: "Please enter client's postal country",
				postalCode:
					"Please enter the postal code for client's postal physical address",
				province: "Please enter client's postal province",
				provinceOther: "Please enter client's postal province",
				suburb: "Please enter client's postal suburb",
			},
			referredByDetails: "How did you hear about us? Please elaborate",
			residential: {
				address: "Please enter client's residential physical address",
				city: "Please enter client's residential city",
				country: "Please enter client's residential country",
				postalCode:
					"Please enter the postal code for client's residential physical address",
				province: "Please enter client's residential province",
				provinceOther: "Please enter client's residential province",
				suburb: "Please enter client's residential suburb",
			},
			secondaryEmail:
				"Please enter client's secondary contact email if one exist",
			taxNumber: "Please enter client's valid tax number",
		},
	},
};

export default messages;
