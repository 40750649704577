import { CustomLoader } from "@app/components/custom-loader";
import { EmailChangeCard } from "@app/components/email-change-card";
import { Footer } from "@app/components/footer";
import { ChangeEmailModal } from "@app/components/modals/change-email-modal";
import { ChangePasswordModal } from "@app/components/modals/change-password-modal";
import { Navbar } from "@app/components/navbar";
import { PasswordChangeCard } from "@app/components/password-change-card";
import { Button } from "@app/controls/button";
import { memo } from "react";

import "./account-settings.css";

export const AccountSettingsView = memo(
	(props: {
		email?: string;
		loading?: boolean;
		openEmailModal: boolean;
		openPasswordModal: boolean;
		password?: string;
		onChangeEmail: () => void;
		onChangePassword: () => void;
		onClose: () => void;
		onNavigateBack: () => void;
	}) => {
		return (
			<>
				{props.loading && <CustomLoader page />}
				<div className="min-h-screen account-settings-container">
					<Navbar />
					<div className="flex">
						<div className="content grow lg:mx-14 mb-7 lg:min-h-screen mx-6">
							<div className="font-secondary-regular mb-6 mt-7 account-settings-header">
								Account Settings
							</div>
							<div className="flex flex-col lg:flex-row gap-x-14 gap-y-5">
								<EmailChangeCard
									value={props.email}
									onChangeEmail={props.onChangeEmail}
								/>
								<PasswordChangeCard
									value={props.password}
									onChangePassword={props.onChangePassword}
								/>
							</div>
							<Button
								className="mt-4 px-6 py-2.5"
								theme="primary"
								onClick={props.onNavigateBack}
							>
								Back
							</Button>
						</div>
					</div>
					<Footer />
				</div>
				{props.openEmailModal && (
					<ChangeEmailModal open onClose={props.onClose} />
				)}
				{props.openPasswordModal && (
					<ChangePasswordModal open onClose={props.onClose} />
				)}
			</>
		);
	},
);
