import type { LogoutResponse, Profile, User } from "@app/entities";
import { concatenateArrays } from "@app/utils/concatenate-arrays";
import { isArray } from "lodash";
import { authorizedApi, unauthorizedApi } from "./api";
import type {
	ActivateUserFailureResponse,
	ConfirmPasswordResetFailureResponse,
	GenericResponse,
	LoginFailureResponse,
	LoginSuccessResponse,
	LogoutFailureResponse,
	MappedReasons,
	RegisterFailureResponse,
	ResendUserActivationFailureResponse,
	ResetPasswordFailureResponse,
	UpdateUserPasswordFailureResponse,
	UpdateUserUsernameFailureResponse,
} from "./models";

export async function login(username: string, password: string): Promise<User> {
	try {
		var { data } = await unauthorizedApi.post<LoginSuccessResponse>(
			"auth/token/login/",
			{
				username: `${username}`,
				password: `${password}`,
			},
		);

		return {
			isAuthentic: true,
			token: data.auth_token,
		};
	} catch (exception: any) {
		var error = exception.data as LoginFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			error.non_field_errors,
			(error.username || []).map((x) => `username: ${x}`),
			(error.password || []).map((x) => `password: ${x}`),
		);

		return {
			isAuthentic: false,
			reasons: reasons,
		};
	}
}

export async function logout(): Promise<LogoutResponse | undefined> {
	try {
		await authorizedApi.post("auth/token/logout/");
	} catch (exception: any) {
		const error = exception.data as LogoutFailureResponse;
		const reasons = concatenateArrays(error.genericErrors, [
			error.detail ?? "",
		]);

		return {
			reasons,
		};
	}
}

export async function register(
	email: string,
	password: string,
): Promise<GenericResponse | undefined> {
	try {
		await unauthorizedApi.post("auth/users/", {
			email: `${email}`,
			password: `${password}`,
		});
	} catch (exception: any) {
		var error: string[] | RegisterFailureResponse = exception.data;

		var reasons = isArray(error)
			? concatenateArrays(error)
			: concatenateArrays(
					error.genericErrors,
					[error.detail ?? ""],
					(error.email || []).map((x) => `email: ${x}`),
					(error.password || []).map((x) => `password: ${x}`),
				);

		var mappedReasons: MappedReasons | undefined = isArray(error)
			? undefined
			: {
					email: error.email,
					password: error.password,
				};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function activateUser(
	userId: string,
	token: string,
): Promise<GenericResponse | undefined> {
	try {
		await unauthorizedApi.post("auth/users/activation/", {
			uid: `${userId}`,
			token: `${token}`,
		});
	} catch (exception: any) {
		var error = exception.data as ActivateUserFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			(error.token || []).map((x) => `token: ${x}`),
			(error.uid || []).map((x) => `uid: ${x}`),
		);

		return {
			reasons: reasons,
		};
	}
}

export async function resendUserActivation(
	email: string,
): Promise<GenericResponse | undefined> {
	try {
		await unauthorizedApi.post("auth/users/resend_activation/", {
			email: `${email}`,
		});
	} catch (exception: any) {
		var error = exception.data as ResendUserActivationFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			(error.email || []).map((x) => `email: ${x}`),
		);

		return {
			reasons: reasons,
		};
	}
}

export async function resetPassword(
	email: string,
): Promise<GenericResponse | undefined> {
	try {
		await unauthorizedApi.post("auth/users/reset_password/", {
			email: `${email}`,
		});
	} catch (exception: any) {
		var error = exception.data as ResetPasswordFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			(error.email || []).map((x) => `${x}`),
		);

		return {
			reasons: reasons,
		};
	}
}

export async function confirmPasswordReset(
	userId: string,
	token: string,
	newPassword: string,
): Promise<GenericResponse | undefined> {
	try {
		await unauthorizedApi.post("auth/users/reset_password_confirm/", {
			uid: `${userId}`,
			token: `${token}`,
			new_password: `${newPassword}`,
		});
	} catch (exception: any) {
		var error = exception.data as ConfirmPasswordResetFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			(error.token || []).map((x) => `token: ${x}`),
			(error.uid || []).map((x) => `user id: ${x}`),
			(error.new_password || []).map((x) => `password: ${x}`),
		);

		var mappedReasons: MappedReasons = {
			newPassword: error.new_password,
			token: error.token,
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function updateUserPassword(
	currentPassword: string,
	newPassword: string,
): Promise<GenericResponse | undefined> {
	try {
		await authorizedApi.post("auth/users/set_password/", {
			new_password: `${newPassword}`,
			current_password: `${currentPassword}`,
		});
	} catch (exception: any) {
		var error = exception.data as UpdateUserPasswordFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			(error.new_password || []).map((x) => `${x}`),
			(error.current_password || []).map((x) => `${x}`),
		);

		var mappedReasons = {
			newPassword: error.new_password,
			currentPassword: error.current_password,
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function updateUserUsername(
	newUsername: string,
	currentPassword: string,
): Promise<GenericResponse | undefined> {
	try {
		await authorizedApi.post("auth/users/set_username/", {
			new_username: `${newUsername}`,
			current_password: `${currentPassword}`,
		});
	} catch (exception: any) {
		var error = exception.data as UpdateUserUsernameFailureResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			(error.new_username || []).map((x) => `${x}`),
			(error.current_password || []).map((x) => `${x}`),
		);

		var mappedReasons = {
			newUsername: error.new_username,
			currentPassword: error.current_password,
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function getUserProfile(): Promise<Profile> {
	try {
		var { data } = await authorizedApi.get<Profile>("auth/users/me/");

		return data;
	} catch (exception: any) {
		var error = exception.data as ResetPasswordFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}
