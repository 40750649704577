const messages = {
	en: {
		accountSettings: "Account Settings",
		add: "(add)",
		change: "(change)",
		clientName: "...",
		dashboard: "Dashboard",
		default: {
			clientName: "...",
			contactNumber: "...",
			email: "...",
			emailAddress: "...",
			rmFirstName: "...",
			rmLastName: "",
		},
		logout: "Logout",
		menu: "Menu",
		relationshipManager: "Your Relationship Manager",
		trades: "Trades",
	},
};

export default messages;
